import React, { useEffect } from 'react';
import {
  Collapse,
  Table,
  TableCell,
  TableBody,
  Box,
  Button,
  TableContainer,
  Chip,
  TableRow,
  TableHead,
  IconButton,
  Typography,
  Modal
} from '@mui/material';
import { makeStyles } from '@material-ui/core';

import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import InvestorStatus from './InvestorStatus';
import { useHttpApi, useWeb3, useSubscriptionState } from 'state';
import { toChecksumAddress } from 'helpers/web3';
import EmptyDashboard from 'assets/images/no-data-image.svg';
import AddressFieldTools from 'components/AddressFieldTools';
import SyncIcon from '@mui/icons-material/Sync';
import { titleCase } from 'helpers/text';
import InvestorDocVewUploadModal from 'components/DocMangement/InvestorDocViewUploadModal';
import UploadIcon from '@mui/icons-material/Upload';
import Synaps from '@synaps-io/react-verify';
import KYCModal from './KYCModal';
import { useSnackbar } from 'notistack';
import { ReactComponent as KeyboardArrowDownIcon } from '../../../assets/images/caratDown.svg';
import { ReactComponent as KeyboardArrowUpIcon } from '../../../assets/images/caratUp.svg';

const useStyles = makeStyles((theme) => ({
  tableRow: {
    '& .MuiTableCell-root': {
      fontFamily: 'BeVietnamPro, sans-serif', // Custom font
      fontSize: '16px', // Font size
      color: '#333' // Text color
    }
  },
  tableHeaderRow: {
    '& .MuiTableCell-root': {
      fontFamily: 'BeVietnamPro, sans-serif', // Custom font
      fontSize: '16px', // Font size
      color: '#333', // Text color
      fontWeight: 'bold',
      backgroundColor: '#F2F2FF',
      borderBottom: 0
    }
  },
  statusChip: {
    fontFamily: 'BeVietnamPro',
    fontSize: '16px',
    // backgroundColor: '#D4FFE5',
    // borderRadius: '4px',
    // color: '#0C1211',
    width: '200px'
  }
}));

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [kycModalOpen, setKycModalOpen] = React.useState(false);
  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 3
  };

  return (
    <>
      <TableRow
        // className={classes.tableRow}
        sx={{
          '& > *': {
            borderBottom: 'unset',
            borderColor: '#CBCBE6',
            fontSize: '16px',
            fontFamily: 'BeVietnamPro'
          }
        }}
      >
        <TableCell component="th" align="left" scope="row">
          {row.token_name}
        </TableCell>
        <TableCell component="th" align="left" scope="row">
          {row.token_symbol}
        </TableCell>
        <TableCell sx={{ width: '1rem' }} component="th" align="left" scope="row" padding="">
          <AddressFieldTools
            address={row.deployment_address}
            symbol={row.token_symbol}
            decimals={0}
            // showAddToWallet
            showInBlockExplorer
            showCopyButton
          />
        </TableCell>

        <TableCell align="center">
          {/* <Chip
            className={classes.statusChip}
            // color={row.current_status === 'FAILED' ? 'error' : 'success'}
            label={titleCase(row.current_status)}
          /> */}
          {titleCase(row.current_status)}
        </TableCell>
        <TableCell align="center">
          <IconButton size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ mt: 2 }}>
              <InvestorStatus
                issuerName={row.issuer_name}
                tokenValue={row.token_value}
                maturityDate={row.final_maturity_date} // TODO
                susbscribedqty={row.requested_qty}
                previousAllotment={row.previous_allotment}
                activeAllotment={row.active_allotment}
                status={row.current_status}
                allotmentDate={row.allotment_date} // TODO
                tokenPrice={row.token_price}
              />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <Modal
        open={modalOpen}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <InvestorDocVewUploadModal handleClose={closeModal} row={row} />
      </Modal>
    </>
  );
}

export default function OpenTransaction() {
  // Get  Current logged in web3 account
  const { account, active } = useWeb3();
  const { openTransaction, setOpenTransaction, fetchOpenTransactionData } = useSubscriptionState();
  const classes = useStyles();

  const fetchData = () => {
    fetchOpenTransactionData(account);
  };
  useEffect(() => {
    // fetchData();
    fetchOpenTransactionData(account);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, active]);

  return (
    <>
      <Box sx={{ background: `linear-gradient(180deg,  #FFF  10%,#EBEBEB 90%)`, px: 6 }}>
        <Box
          sx={{
            mt: 2,
            // ml: 4,
            display: 'flex',
            justifyContent: 'flex-between'
          }}
        >
          <Typography
            variant="h4"
            sx={{
              fontSize: '1.125rem',
              fontWeight: 'bold',
              mt: '5px'
            }}
          >
            Transactions
          </Typography>
          <IconButton sx={{ ml: 1 }} onClick={fetchData}>
            <SyncIcon />
          </IconButton>
        </Box>
        <TableContainer sx={{ mt: 3 }}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow
                sx={{
                  '& > *': {
                    // borderColor: '#CBCBE6',
                    fontSize: '16px',
                    fontWeight: 'bold !important',
                    backgroundColor: '#F2F2FF',
                    fontFamily: 'BeVietnamPro'
                  }
                }}
              >
                <TableCell>Token Name</TableCell>
                <TableCell>Symbol</TableCell>
                <TableCell>Token Address</TableCell>
                <TableCell align="center">Transaction Status</TableCell>
                <TableCell align="center">Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {openTransaction.map((row) => (
                <Row key={row.name} row={row} />
              ))}
              {openTransaction.length === 0 && (
                <TableCell
                  colSpan={4}
                  sx={{
                    textAlign: 'center'
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    <Box
                      img
                      sx={{ ml: '10rem' }}
                      component="img"
                      src={EmptyDashboard}
                      alt="Empty Dashboard"
                    />
                    <Typography sx={{ ml: '10rem', fontWeight: 'bold' }} variant="p">
                      {active ? 'No open transaction found' : 'Please connect to Web3 Wallet'}
                    </Typography>
                  </Box>
                </TableCell>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
}
