/* eslint-disable react/prop-types */
import {
  Dialog,
  DialogTitle,
  Table,
  TableCell,
  Typography,
  TableRow,
  IconButton,
  styled,
  Button,
  DialogActions,
  DialogContent
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import { useBusinessEntityState, useHttpApi, useWeb3, useCoreTableState, useAppState } from 'state';
import { ethToXdcAddress, stringToBytes32 } from 'helpers/web3';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import { daysToSeconds, parseTokenDecimal } from 'helpers/numbers';
import { isDevEnv } from 'helpers/dev';
import { USE_TIME_IN_DAYS } from 'config';
import NAIcon from 'components/NAIcon';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(0.25)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(0.25)
  }
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {/* {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'right',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null} */}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired
};

const PublishTokenDialog = (props) => {
  const { openPopup, setOpenPopup } = props;
  const [rules, setRules] = useState(null);
  const { getRuleTemplateByToken, getTokenDetailsById, fetchCurrentToken } = useHttpApi();
  const { currentToken: token } = useBusinessEntityState();
  const { throwErrorMessage } = useAppState();
  const { paymentTokens } = useCoreTableState();
  const { enqueueSnackbar } = useSnackbar();
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const handleClose = () => {
    setOpenPopup(false);
  };
  const navigate = useNavigate();

  const { publishToken, account, web3 } = useWeb3();

  // console.log('token from publish', token);

  return (
    <>
      <BootstrapDialog
        open={openPopup}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          sx={{ fontWeight: '900' }}
          onClose={handleClose}
        >
          Publish Token on XinFin Blockchain
        </BootstrapDialogTitle>

        <DialogContent>
          <Table aria-label="simple table">
            <TableRow>
              <TableCell>
                <Typography sx={{ fontWeight: '600' }}>Token Name</Typography>
              </TableCell>
              <TableCell>
                <Typography>{token.token_name}</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography sx={{ fontWeight: '600' }}>Token Symbol</Typography>
              </TableCell>
              <TableCell>
                <Typography>{token.token_symbol}</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography sx={{ fontWeight: '600' }}>Token Supply</Typography>
              </TableCell>
              <TableCell>
                <Typography>{token.total_supply}</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography sx={{ fontWeight: '600' }}>Token Price</Typography>
              </TableCell>
              <TableCell>
                <Typography>{token.token_nav}</Typography>
              </TableCell>
            </TableRow>
          </Table>
        </DialogContent>
        <DialogActions sx={{ mt: 2, mb: 2 }}>
          <Button
            autoFocus
            sx={{ width: '7.75rem', height: '3.125rem' }}
            variant="contained"
            color="secondary"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <LoadingButton
            autoFocus
            loadingPosition="start"
            loading={isSubmitting}
            variant="gradient"
            sx={{ width: '7.75rem', height: '3.125rem' }}
            onClick={async () => {
              try {
                if (account === token.issuer_id.primary_issuer_xinfin_address) {
                  // Trying Publishing token from Issuer Account without restriction
                  setIsSubmitting(true);
                  const res = await publishToken(
                    token.token_name,
                    token.token_symbol,
                    Number(token.total_supply),
                    token.token_nav*100
                  );

                  if (res) {
                    console.log('res', res);
                    handleClose();
                    enqueueSnackbar('Token Publish transaction sent to blockchain', {
                      variant: 'success'
                    });
                    navigate('/home/token-dashboard');
                  }
                  setIsSubmitting(false);
                } else {
                  enqueueSnackbar(
                    'You are not authorised to publish this token, Please use a issuer account',
                    {
                      variant: 'error'
                    }
                  );
                }
              } catch (e) {
                console.log(e);
                throwErrorMessage(e);
                handleClose();
                setIsSubmitting(false);
              }
            }}
          >
            Submit
          </LoadingButton>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
};

export default PublishTokenDialog;
