import React, { useEffect, useState } from 'react';
import { IconButton } from '@mui/material';
import { useBusinessEntityState } from 'state/useBusinessEntityState';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useAuthStateShared } from 'state';
import EnhancedTable from 'components/tables/EnhancedTable';
import CreateIcon from '@mui/icons-material/Create';
import IdentityInformationModal from 'components/issuerDashboard/IdentityInformationModal';
import AccountInformationModal from 'components/issuerDashboard/AccountInformationModal';
import MailComponent from 'components/issuerDashboard/MailComponent';
import AddressFieldTools from 'components/AddressFieldTools';

export default function ViewAllotmentTable() {
  const { allAllotments, fetchAllAllotments, setCurrentRedeemAllotmentList } =
    useBusinessEntityState();
  const [data, setData] = useState(allAllotments);
  const { role } = useAuthStateShared();

  const [skipPageReset, setSkipPageReset] = React.useState(false);
  useEffect(() => {
    fetchAllAllotments();
  }, []);
  const ActionButtons = ({ investor }) => {
    return (
      <>
        <IdentityInformationModal investorId={investor.investor_id} />
        <AccountInformationModal investorId={investor.investor_id} />
        <MailComponent email={investor.investor_email_id} subject="" body="" />
      </>
    );
  };

  const headCells = [
    {
      accessor: 'token_subscription_id.investor_id.investor_name',
      Header: 'Customer Name',
      width: 200,
      show: true
    },
    {
      accessor: 'xinfin_account',
      Header: 'Blockchain Address',
      show: true,
      width: 250,
      Cell: ({ row: { original } }) => {
        return (
          <AddressFieldTools
            address={original.token_subscription_id.xinfin_setl_account.xinfin_account_address}
            decimals={0}
            showCopyButton
            showInBlockExplorer
          />
        );
      }
    },
    {
      accessor: 'allotment_date',
      Header: 'Allotment Date',
      width: 200,
      show: true
    },
    {
      accessor: 'token_subscription_id.requested_qty',
      Header: 'Qty Alloted',
      width: 150,
      show: true
    },
    {
      accessor: 'token_subscription_id.original_token_subscription_buy_price',
      Header: 'Transaction Price/Token ($)',
      width: 150,
      show: true
    },
    {
      accessor: 'alloted_qty',
      Header: 'Active Holdings',
      width: 200,
      show: true
    },
    // {
    //   accessor: 'token_subscription_id.token_price',
    //   Header: 'Last Reset Price/Token ($)',
    //   width: 150,
    //   show: true
    // },
    {
      accessor: 'transaction_amount',
      Header: 'Amount Received ($)',
      width: 220,
      show: true
    },
    {
      accessor: 'platform_fees_amount',
      Header: 'Fees ($)',
      show: true
    },
    {
      accessor: 'sales_person',
      Header: 'Sales Person',
      width: 170,
      show: true
    },
    {
      accessor: 'token_subscription_id.subscription_payment_method.payment_method',
      Header: 'Payment Method',
      show: true,
      width: 200
    },
    {
      accessor: 'actions',
      Header: 'Actions',
      Cell: ({ row: { original } }) => {
        console.log(original);
        return <ActionButtons investor={original.token_subscription_id.investor_id} />;
      },
      show: true
    }
  ];

  // We need to keep the table from resetting the pageIndex when we
  // Update data. So we can keep track of that flag with a ref.

  // When our cell renderer calls updateMyData, we'll use
  // the rowIndex, columnId and new value to update the
  // original data
  const updateMyData = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    setSkipPageReset(true);
    setData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value
          };
        }
        return row;
      })
    );
  };

  return (
    <>
      <EnhancedTable
        tableTitle="View Allotment"
        columns={headCells}
        refreshFunction={fetchAllAllotments}
        setSelectedRowsToState={setCurrentRedeemAllotmentList}
        data={allAllotments}
        setData={setData}
        updateMyData={updateMyData}
        skipPageReset={skipPageReset}
        isRoleAllowed={['PRIMARY_ISSUER', 'ISSUER_OPERATOR', 'INVESTOR'].includes(role)}
      />
    </>
  );
}
