import {
  Typography,
  IconButton,
  Box,
  TextField,
  Button,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox
} from '@mui/material';
import Modal from '@mui/material/Modal';
import { useState, useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useWeb3State } from 'state/useWeb3State';
import { useAppState, useBusinessEntityState, useHttpApi } from 'state';
import { useSnackbar } from 'notistack';
import LoadingActionButton from 'helpers/LoadingActionButton';

const BTLBurnModal = ({ open, setOpenModal, tokenBalance }) => {
  const [processBurn, setProcessBurn] = useState(false);
  const [burnQuantity, setBurnQuantity] = useState('');
  const { account, burn } = useWeb3State();
  const { currentToken } = useBusinessEntityState();
  const { throwErrorMessage } = useAppState();
  const { enqueueSnackbar } = useSnackbar();
  const { getReservedTokenCount } = useHttpApi();

  const handleClose = () => {
    setOpenModal(false);
    setProcessBurn(false);
    setBurnQuantity('');
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableEscapeKeyDown
      disableEnforceFocus
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          width: 'auto',
          p: 4,
          boxShadow: 24,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          borderRadius: '10px'
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'flex-start', mt: 2 }}>
          <Button sx={{ width: '15rem', mx: 2 }} variant="contained">
            BTL Balance
          </Button>
          <TextField
            fullWidth
            size="small"
            autoComplete="off"
            type="text"
            placeholder="Enter Token Quantity"
            id="tokenQuantity"
            inputProps={{ maxLength: 50, width: 'auto', readOnly: true }}
            value={tokenBalance}
          />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-start', mt: 2 }}>
          <Button sx={{ width: '15rem', mx: 2 }} variant="contained">
            Burn From
          </Button>
          <TextField
            fullWidth
            size="small"
            autoComplete="off"
            type="text"
            placeholder="Enter quantity to mint"
            id="mintQuantity"
            inputProps={{ maxLength: 50, readOnly: true, width: 'auto' }}
            value={account}
          />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-start', mt: 2 }}>
          <Button sx={{ width: '15rem', mx: 2 }} variant="contained">
            Tokens
          </Button>
          <TextField
            fullWidth
            size="small"
            autoComplete="off"
            type="text"
            placeholder="Enter Token Quantity"
            id="tokenQuantity"
            inputProps={{ maxLength: 50, width: 'auto' }}
            onChange={(e) => {
              setBurnQuantity(e.target.value);
            }}
          />
        </Box>
        {burnQuantity && (
          <Box sx={{ display: 'flex', justifyContent: 'flex-start', mt: 2, mx: 2 }}>
            <FormGroup>
              <FormControlLabel
                required
                control={<Checkbox />}
                onChange={(e) => {
                  setProcessBurn(e.target.checked);
                }}
                label={`Are you sure you want to Burn ${burnQuantity} Tokens from your account`}
              />
            </FormGroup>
          </Box>
        )}
        <Box sx={{ display: 'flex', justifyContent: 'flex-start', mt: 2 }}>
          <LoadingActionButton
            disabled={!processBurn}
            loadingPosition="start"
            sx={{
              width: '7.5rem',
              height: '2.5rem',
              mt: 3,
              mx: 1
            }}
            variant="contained"
            onClick={async () => {
              try {
                const reservedTokenCount = await getReservedTokenCount(currentToken?.token_id);

                if (burnQuantity && burnQuantity > 0) {
                  if (
                    tokenBalance > 0 &&
                    tokenBalance < Number(reservedTokenCount.data) + Number(burnQuantity)
                  ) {
                    enqueueSnackbar(
                      'You do not have sufficient tokens for burn due to other open transactions in progress',
                      { variant: 'error' }
                    );
                  } else {
                    await burn(currentToken?.deployment_address, burnQuantity);
                    enqueueSnackbar('Token Burn in progress', {
                      variant: 'success'
                    });
                    setBurnQuantity('');
                    handleClose();
                  }
                } else {
                  enqueueSnackbar('Enter a valid token quantity', { variant: 'error' });
                }
              } catch (e) {
                throwErrorMessage(e);
              }
            }}
          >
            Burn
          </LoadingActionButton>
          <Button
            sx={{ width: '7.5rem', height: '2.5rem', mt: 3, mx: 1, backgroundColor: 'black' }}
            variant="contained"
            onClick={handleClose}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default BTLBurnModal;
