import { Box, Container, Typography, Button, Card, CardContent } from '@mui/material';
import Breadcrumbs from 'components/Breadcrumbs';
import AstraCashTransactionTable from 'components/issuerDashboard/AstraCashTransactionTable';
import CheckboxTokenTable from 'components/tables/CheckboxTokenTable';
import React, { useEffect, useMemo, useState } from 'react';
import { useAppState, useBusinessEntityState, useHttpApi } from 'state';
import { useSnackbar } from 'notistack';
import LoadingActionButton from 'helpers/LoadingActionButton';
import RbacShowHideComponent from 'components/RbacShowHideComponent';

const BTLFeesCharges = () => {
  const [data, setData] = useState([]);
  const [selectedRowData, setSelectedRowData] = useState();

  const {
    getFeesChargesTransactionBytokenId,
    payTDAFeesByAstraIds,
    getOfflineFeesChargesTransactionBytokenId
  } = useHttpApi();

  const checkboxDisabledStatuses = ['Paid', 'In Progress'];

  const columns = useMemo(() => {
    return [
      {
        Header: 'Customer Name',
        accessor: 'investor_name',
        Cell: ({ value }) => <Typography>{value}</Typography>,
        width: 150
      },
      {
        Header: 'Email',
        accessor: 'investor_email',
        Cell: ({ value }) => <Typography>{value}</Typography>,
        width: 200
      },
      {
        Header: 'Transaction Type',
        accessor: 'token_transaction_type',
        Cell: ({ value }) => <Typography>{value}</Typography>
      },
      {
        Header: 'Qty',
        accessor: 'token_quantity',
        Cell: ({ value }) => <Typography>{value}</Typography>
      },
      {
        Header: 'Txn Date',
        accessor: 'start_date',
        Cell: ({ value }) => <Typography>{value}</Typography>
      },
      {
        Header: 'Token Price',
        accessor: 'token_nav',
        Cell: ({ value }) => <Typography>{value}</Typography>
      },
      {
        Header: 'Fees(%)',
        accessor: 'tda_fees_percentage',
        Cell: ({ value }) => <Typography>{value}</Typography>
      },
      {
        Header: 'Trade Consideration',
        accessor: 'amount',
        Cell: ({ value }) => <Typography>{value}</Typography>
      },
      {
        Header: 'TDA Fees',
        accessor: 'tda_fees',
        Cell: ({ value }) => <Typography>{value}</Typography>
      },
      {
        Header: 'Beneficiary',
        accessor: 'beneficiary',
        Cell: ({ row: { original } }) =>
          original.token_transaction_type === 'BUY' ? 'Bettor Token LLC' : 'Customer'
      },
      {
        Header: 'Payment Status',
        accessor: 'fees_paid_status',
        Cell: ({ value }) => value
      }
    ];
  }, [data]);

  const { currentToken: token } = useBusinessEntityState();
  const { enqueueSnackbar } = useSnackbar();
  const { throwErrorMessage } = useAppState();

  const fetchFeesChargesTransaction = async () => {
    const newfeesChargesTransaction = await getOfflineFeesChargesTransactionBytokenId(
      token?.token_id
    );
    console.log('newfeesChargesTransaction', newfeesChargesTransaction);
    // The below variable feesChargesTransaction is for online payment
    // which has been disabled currently, so using the offline table data only
    const feesChargesTransaction = await getFeesChargesTransactionBytokenId(token?.token_id);
    setData(newfeesChargesTransaction);
  };

  useEffect(() => {
    fetchFeesChargesTransaction();
  }, []);

  return (
    <Container>
      <Breadcrumbs aria-label="breadcrumb" pageHead="" />

      {/* Token Table */}

      <AstraCashTransactionTable currToken={token} />
      {/* Allotments  Table */}
      <Box marginTop={4}>
        <Typography
          variant="body2"
          color="initial"
          sx={{
            mt: 4,
            mb: 2,
            fontSize: '1.125rem',
            fontWeight: 'bold'
          }}
        >
          Fees & Charges
        </Typography>
        <Card>
          <CardContent>
            <CheckboxTokenTable
              columns={columns}
              tableTitle="Fees & Charges"
              data={data}
              updateMyData={() => {}}
              refreshFunction={fetchFeesChargesTransaction}
              setSelectedRowsToState={setSelectedRowData}
              fieldName="id" // Optional field - Please change the name according to your field name.
              // disableRowCondition if fees_paid_status is Paid or In Progress
              disableRowCondition={(row) => checkboxDisabledStatuses.includes(row.fees_paid_status)}
            />

            {/* SUBMIT BUTTONS */}
            <RbacShowHideComponent allowedRoles={['PRIMARY_ISSUER']}>
              <Box
                sx={{
                  mt: 4
                }}
                display="flex"
                justifyContent="end"
                alignItems="center"
              >
                <LoadingActionButton
                  variant="gradient"
                  disabled
                  onClick={async () => {
                    try {
                      console.log('selectedData', selectedRowData);
                      const response = await payTDAFeesByAstraIds({ astra_ids: selectedRowData });
                      if (response) {
                        enqueueSnackbar('TDA Fees payment is processed', { variant: 'info' });
                      }
                    } catch (error) {
                      throwErrorMessage(error);
                    }
                  }}
                >
                  Pay TDA Fees
                </LoadingActionButton>
              </Box>
            </RbacShowHideComponent>
          </CardContent>
        </Card>
      </Box>
    </Container>
  );
};

export default BTLFeesCharges;
