import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';

import Footer from './main/Footer';

import SubscriptionNavbar from 'components/SubscriptionNavbar';
import RequireNetworkOverlay from 'components/RequireNetworkOverlay';

const SubscriptionLayout = () => {
  return (
    <>
      <SubscriptionNavbar />
      <Box sx={{ position: 'relative', flex: '1' }}>
        {/* <RequireNetworkOverlay /> */}
        <Outlet />
      </Box>
        <Footer />
    </>
  );
};

export default SubscriptionLayout;
