import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { userTokenPersistence } from 'persistence';
import { useCoreTableState } from 'state';
import AddInvestor from './subscriptions/AddInvestor';

const Signup = () => {
  const navigate = useNavigate();
  const userToken = userTokenPersistence.get();

  useEffect(() => {
    if (userToken) {
      navigate('/home');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToken]);

  const { fetchOrganizations } = useCoreTableState();

  useEffect(() => {
    fetchOrganizations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <AddInvestor />;
};

export default Signup;
