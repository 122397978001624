import { Container, Box, Typography } from '@mui/material';
import React from 'react';
import banner from '../../assets/images/header_banner_image.png';

const SubscriptionHead = ({ title }) => {
  return (
    <Box
      sx={{
        position: 'relative',
        height: '8rem', // Adjust the height as needed
        width: '100%',
        overflow: 'hidden'
      }}
    >
      <Box
        component="img"
        src={banner}
        alt="banner"
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          objectPosition: 'center 40%',
          zIndex: 1
        }}
      />
      <Container
        sx={{
          position: 'relative',
          zIndex: 3,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%'
        }}
      >
        <Typography
          variant="h3"
          sx={{
            fontFamily: 'Cy Extrabold',
            color: '#fff',
            fontSize: '21px',
            fontWeight: '600',
            textAlign: 'center'
          }}
        >
          {title}
        </Typography>
      </Container>
    </Box>
  );
};

export default SubscriptionHead;
