import { Link as RouterLink, Outlet } from 'react-router-dom';

import { experimentalStyled as styled, Box, Grid, Typography } from '@mui/material';
import btlLogo from '../assets/images/bettor-token-logo.png';
import Page from '../components/Page';
import { BACKGROUND_VIDEO_URL } from '../config';


const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  left: 0,
  lineHeight: 0,
  width: '100%',
  position: 'absolute',
  padding: theme.spacing(3, 3, 0),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(5, 5, 0)
  }
}));

// const RootStyle = styled(Page)(({ theme }) => ({
//   [theme.breakpoints.up('md')]: {
//     display: 'flex',
//     background: 'transparent',
//     height: '100vh'
//   }
// }));

const RootStyle = styled(Page)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column', // Ensure the content is stacked vertically
  justifyContent: 'center', // Center vertically on small screens
  alignItems: 'center', // Center horizontally on small screens
  height: '100vh',
  background: 'transparent',
  [theme.breakpoints.up('sm')]: {
    justifyContent: 'center', // Center vertically on medium screens and up
    alignItems: 'flex-end', // Align content to the right on large screens
    paddingRight: '20px', // Optional: Adjust right padding/margin as needed
    marginX: 'auto',
    paddingLeft: '10px'
  }
}));

const LogoOnlyLayout = () => {
  console.log('background video url - ', BACKGROUND_VIDEO_URL);
  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        height: '100vh',
        overflow: 'hidden'
      }}
    >
      <Box
        component="video"
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          zIndex: -1
        }}
        autoPlay
        loop
        muted
        src={BACKGROUND_VIDEO_URL}
      />
      <Box
        component="img"
        src={btlLogo}
        alt="Logo"
        sx={{
          position: 'absolute',
          top: '20px',
          left: '20px',
          width: '100px',
          zIndex: 1
        }}
      />

      <HeaderStyle>
        <RouterLink to="/" />
      </HeaderStyle>
      <RootStyle title="Login BettorToken Sports Exchange">
        <Grid container>
          <Grid item lg={6} md={1} xs={0}>
            <Typography
              variant="h4"
              sx={{
                marginTop: '-10vh',
                marginLeft: '10vh',
                color: '#fff',
                display: 'none'
              }}
            >
              BettorToken Sports Exchange
            </Typography>
          </Grid>
          <Grid alignSelf="center" sx={{ mx: 'auto' }} item lg={5} md={6}>
            <Outlet />
          </Grid>
        </Grid>
      </RootStyle>
    </Box>
  );
};

export default LogoOnlyLayout;
