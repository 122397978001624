import {
  Container,
  Card,
  Grid,
  Box,
  CardContent,
  Typography,
  TextField,
  FormLabel,
  Button,
  Alert,
  AlertTitle,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Modal,
  Divider
} from '@mui/material';

import Page from 'components/Page';
import SubscriptionHead from 'components/subscritiption/SubscriptionHead';
import React, { useState, useRef, useEffect } from 'react';
import InvestorTokenTable from 'components/subscritiption/InvestorTokenTable';
import { useFormik, Form, FormikProvider, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import dropdownIcon from '../../assets/images/dropdown.svg';
import {
  useBusinessEntityState,
  useSubscriptionState,
  useCoreTableState,
  useHttpApi,
  useWeb3,
  useAppState,
  useAuthStateShared
} from 'state';

import { useNavigate } from 'react-router';
import { useSnackbar } from 'notistack';
import FocusError from 'components/FocusError';
// import { ethToXdcAddress } from 'helpers/web3';

import MuiPhoneInput from 'material-ui-phone-number';
import parsePhoneNumber from 'libphonenumber-js';
import { debounce } from 'helpers/handler';
// import WalletRequireOverlay from 'components/WalletRequireOverlay';
import KYCModal from 'components/masterData/Investor/KYCModal';
// import { rules } from 'eslint-config-prettier';
import CircularProgress from '@material-ui/core/CircularProgress';
import PaymentModal from 'components/PaymentModal';
import TermsAndConditionsmodal from 'components/subscritiption/TermsAndConditionsmodal';
import LoadingActionButton from 'helpers/LoadingActionButton';

const DropdownIcon = () => (
  <img
    src={dropdownIcon}
    alt="Dropdown Icon"
    style={{ width: '20px', height: '9px', marginRight: '10px' }}
  />
);

const SubscriptionForm = () => {
  const { tokenAddress } = useParams();
  const {
    setSubsToken,
    currentSubsToken,
    setCurrentTokenRuleDetails,
    currentTokenRuleDetails,
    fetchInvestorsByIssuer,
    investorsByIssuer
  } = useBusinessEntityState();
  const { account } = useWeb3();
  const {
    createSubscription: subscribe,
    getTokenByDeploymentAddress,
    getRuleTemplateDetailedByToken,
    getInvestorIssuerMapping,
    createIssuerInvestorMapping,
    getIDVList,
    createAstraRoutine,
    getAllSalesPerson,
    getBtlTdaBankAccountDetails
  } = useHttpApi();
  const { subscription, toUpdate, getSubscriberDetails } = useSubscriptionState();
  const [readOnlyTranch, setReadOnlyTranch] = useState(false);
  const [kycModalOpen, setKycModalOpen] = React.useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [issuerInvestorMap, setIssuerInvestorMap] = useState([]);
  const [astraStatus, setAstraStatus] = useState(false);
  const [astraAuthUrl, setAstraAuthUrl] = useState('');
  const [salesPersons, setSalesPersons] = useState();
  const [btlTdaBankAccount, setBtlTdaBankAccount] = useState('');
  const [offlineMode, setOfflineMode] = useState(true);
  const [showTermsAndCondition, setShowTermsAndCondition] = useState(false);
  const contractDoc = useRef(null);

  const { countries, paymentMethod, fetchPaymentMethod, fetchCountries } = useCoreTableState();

  useEffect(() => {
    fetchCountries();
  }, []);

  const [isLoading, setIsLoading] = useState(true);

  const [openPaymentModal, setOpenPaymentModal] = useState(false);
  const handleOpenPaymentModal = () => setOpenPaymentModal(true);
  const handleClosePaymentModal = () => {
    setOpenPaymentModal(false);
    navigate('/subscription/investor-dashboard');
  };

  // Handle Terms and Conditions Modal
  const handleOpenTermsAndCondition = () => setShowTermsAndCondition(true);
  const handleCloseTermsAndCondition = () => setShowTermsAndCondition(false);

  // To fetch token from token address in URL and maintain state of susbsToken
  useEffect(async () => {
    const res = await getTokenByDeploymentAddress(tokenAddress);
    if (res) {
      setSubsToken(res);
      if (res.onchain_restrictions) {
      }
      const rules = await getRuleTemplateDetailedByToken(res.token_id);
      setCurrentTokenRuleDetails(rules);
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps

    // Fetching the Astra status
    const astraResponse = await getIDVList();
    setAstraAuthUrl(astraResponse.astra_auth_url);
    if (astraResponse.astra_status === true) {
      // Create a transaction otherwise redirect to the astra auth page.
      setAstraStatus(true);
    }
  }, []);

  const [fetchedData, setFetchedData] = useState(false);
  useEffect(async () => {
    const salesPersonResponse = await getAllSalesPerson();
    setSalesPersons(salesPersonResponse);
    const bankDetails = await getBtlTdaBankAccountDetails('BTL');
    setBtlTdaBankAccount(bankDetails);
    fetchPaymentMethod();
  }, []);

  const fetchData = async () => {
    setIsLoading(true);
    setDisableSubmit(false);
    setIsTokenSubscribedByInvestor(false);
    setReadOnlyTranch(false);
    console.log('check', account, currentSubsToken);
    if (account && currentSubsToken) {
      const res = await getSubscriberDetails(account, currentSubsToken?.token_id);
      console.log('check');
      if (res) {
        setFetchedData(true);
      }
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
  };

  useEffect(() => {
    fetchData();
  }, [account, currentSubsToken]);

  const { throwErrorMessage } = useAppState();
  const [error, setError] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const [isTokenSubscribedByInvestor, setIsTokenSubscribedByInvestor] = useState(false);

  const [subInitialValues, setSubInitialValues] = useState({
    investor_name: '',
    investor_email: '',
    investor_phone: '',
    requested_qty: '',
    investor_country_code: '',
    investor_type: '',
    redeemcheck: false,
    redeemdate: '',
    tranche: '',
    terms_conditions_accepted: false,
    signed_token_term_sheet: '',
    sales_person: '',
    payment_method: ''
  });

  const navigate = useNavigate();

  const [investorPhone, setInvestorPhone] = useState(subscription?.investor.investor_phone_no);
  const [invalidPhone, setInvalidPhone] = useState();

  useEffect(() => {
    setInvalidPhone(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscription?.investor.investor_phone_no]);

  useEffect(async () => {
    console.log('subscription', subscription);
    if (subscription) {
      const response = await getInvestorIssuerMapping(
        currentSubsToken?.issuer_id?.issuer_id,
        subscription?.investor_id
      );
      setIssuerInvestorMap(response);
    }
  }, [subscription]);

  const ConfirmationModal = ({ open, handleClose, handleConfirm }) => {
    return (
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            width: 600,
            p: 4,
            boxShadow: 24,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'left',
            borderRadius: '12px'
          }}
        >
          <Typography sx={{fontFamily:'BeVietnamPro', fontWeight:400}}>{modalMessage}</Typography>
          {offlineMode && (
            <Box sx={{
              mt:2,
              mb:2
            }}>
              <Typography sx={{fontFamily:'BeVietnamPro', fontWeight:600}}>Beneficiary Name - {btlTdaBankAccount.beneficiary_name} </Typography>
              <Divider sx={{ my: 1 }} />
              <Typography sx={{fontFamily:'BeVietnamPro', fontWeight:600}}>Account Number - {btlTdaBankAccount.account_number}</Typography>
              <Divider sx={{ my: 1 }} />
              <Typography sx={{fontFamily:'BeVietnamPro', fontWeight:600}}>Routing Number - {btlTdaBankAccount.routing_number}</Typography>
              <Divider sx={{ my: 1 }} />
              <Typography sx={{fontFamily:'BeVietnamPro', fontWeight:600}}>Bank Name - {btlTdaBankAccount.bank_name}</Typography>
              <Divider sx={{ my: 1 }} />
              <Typography sx={{fontFamily:'BeVietnamPro', fontWeight:600}}>Currency - {btlTdaBankAccount.currency}</Typography>
              <Divider sx={{ my: 1 }} />
              <Typography sx={{fontFamily:'BeVietnamPro', fontWeight:600}}>SWIFT/BIC code - {btlTdaBankAccount.swift_bic_code}</Typography>
              <Divider sx={{ my: 1 }} />
              {/* <Typography sx={{fontFamily:'BeVietnamPro', fontWeight:600}}>Beneficiary Address - {btlTdaBankAccount.beneficiary_address}</Typography> */}
            </Box>
          )}
          <Box>
            <Typography sx={{fontFamily:'BeVietnamPro', fontWeight:400}}>Are you sure you want to purchase?</Typography>
          </Box>

          {/* Container for the buttons */}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'left',
              mt: 2 
            }}
          >
            <Button
              sx={{
                px: 3,
                mr: 4, // Space between the buttons
                width: '7.75rem',
                height: '2.5rem'
              }}
              variant="contained"
              color="secondary"
              onClick={handleClose}
            >
              Cancel
            </Button>

            <LoadingActionButton
              sx={{
                width: '7.5rem',
                height: '2.5rem',
                backgroundColor:'#007BFF'
              }}
              variant="contained"
              loadingPosition="start"
              onClick={handleConfirm}
            >
              Submit
            </LoadingActionButton>
          </Box>
        </Box>
      </Modal>
    );
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState(null);
  const [modalMessage, setModalMessage] = useState('');

  const CreateSubscriptionSchema = Yup.object().shape({
    investor_name: Yup.string().required('Name is required'),
    investor_email: Yup.string().email('Invalid Email Format').required('Email is required'),
    investor_country_code: Yup.string().required('Country code is required'),
    investor_type: Yup.string().required('Select the type of investor'),
    requested_qty: Yup.number()
      .required('Token Quantity is required')
      .positive('Token Quantity must be positive number'),
    terms_conditions_accepted: Yup.boolean().oneOf(
      [true],
      'Please accept the Terms and Conditions'
    ),
    sales_person: Yup.string().required(
      'Sales person is required. If not applicable please select None'
    ),
    payment_method: Yup.string().required('Payment Mode is required.')
  });

  useEffect(() => {
    if (
      (subscription?.token_id === currentSubsToken?.token_id &&
        subscription?.token_subscription_details?.investor_id.xinfin_account ===
          issuerInvestorMap?.investor_xinfin_account &&
        subscription?.token_subscription_details?.current_active_allotment > 0) ||
      subscription?.token_subscription_details?.is_issuance_initiated
    ) {
      setDisableSubmit(true);
    }
  }, [subscription, issuerInvestorMap]);

  useEffect(() => {
    // Automatically select the single payment method if there's only one option
    if (paymentMethod && paymentMethod.length === 1) {
      formik.setFieldValue('payment_method', paymentMethod[0].payment_method);
    }
  }, [paymentMethod]);

  useEffect(() => {
    setIsTokenSubscribedByInvestor(false);
    if (toUpdate && issuerInvestorMap && issuerInvestorMap?.investor_xinfin_account === account) {
      setIsTokenSubscribedByInvestor(true);
    }

    if (
      toUpdate &&
      subscription?.token_subscription_details &&
      subscription?.token_id === currentSubsToken?.token_id &&
      issuerInvestorMap &&
      issuerInvestorMap?.investor_xinfin_account === account
    ) {
      setReadOnlyTranch(true);
    }

    formik.setFieldValue('investor_name', toUpdate ? subscription?.investor?.investor_name : '');
    formik.setFieldValue(
      'investor_email',
      toUpdate ? subscription?.investor?.investor_email_id : ''
    );
    formik.setFieldValue(
      'investor_phone',
      toUpdate ? subscription?.investor?.investor_phone_no : ''
    );
    formik.setFieldValue('lei', toUpdate ? subscription?.investor?.lei : '');
    formik.setFieldValue(
      'investor_country_code',
      toUpdate ? subscription?.investor?.investor_country_code : ''
    );
    formik.setFieldValue(
      'tranche',
      toUpdate &&
        subscription?.token_subscription_details &&
        subscription?.token_id === currentSubsToken?.token_id
        ? subscription?.token_subscription_details?.tranche
        : ''
    );
    formik.setFieldValue('investor_type', toUpdate ? subscription?.investor?.investor_type : '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscription, toUpdate, currentSubsToken, navigate, account, issuerInvestorMap]);

  const subscriptionData = async () => {
    const data = formik.values;
    data.xinfin_address = account;
    data.token_id = currentSubsToken?.token_id;
    data.token_price = currentSubsToken?.token_nav;
    if (currentSubsToken?.token_type !== 'PROXY_TOKEN') {
      data.tranche = 'SENIOR';
    }

    const res = await subscribe(data);
    console.log('subscribe data - - ', res);

    if (res) {
      return res;
    } else {
      return null;
    }
  };

  const formik = useFormik({
    initialValues: subInitialValues,

    validate: (values) => {
      const errors = {};
      if (!values.signed_token_term_sheet) {
        errors.signed_token_term_sheet = 'Signed Token Term sheet is required.';
      }
      if (values.requested_qty > currentSubsToken?.total_supply) {
        errors.requested_qty = 'Token Quantity cannot be more than total supply';
      } else if (values.requested_qty < currentSubsToken?.minimum_subscription) {
        errors.requested_qty = 'Token Quantity cannot be less than Minimum Subscription';
      }
      // Use precision adjustment to handle floats
      const precision = 1e2; // Scale factor to avoid floating-point errors
      
      const requestedQty = Math.round(parseFloat(values.requested_qty) * precision);
      const minimumSubscription = Math.round(parseFloat(currentSubsToken?.minimum_subscription) * precision);
      
      console.log('requested qty - ', requestedQty);
      console.log('min subs - ', minimumSubscription);
      if (requestedQty % minimumSubscription !== 0) {
        errors.requested_qty = 'Token Quantity should be a multiple of the Minimum Subscription (lot size)';
      }
      if (values.investor_phone === '') {
        errors.investor_phone = 'Enter Phone Number';
      }
      if (invalidPhone) {
        errors.investor_phone = 'Enter Valid Phone Number';
      }

      return errors;
    },

    validationSchema: CreateSubscriptionSchema,

    onSubmit: async (data, { setSubmitting, resetForm }) => {
      setFormData(data);
      if (data.payment_method === 'ONLINE-ASTRA' && astraStatus === false) {
        enqueueSnackbar(
          'Complete ASTRA Verification to proceed with Online payment, else select Offline payment mode.',
          {
            variant: 'warning'
          }
        );
      } else {
        setIsModalOpen(true); // Open the confirmation modal on submit click

        if (data.payment_method === 'ONLINE-ASTRA') {
          const message = `Payment towards purchase of ${data.requested_qty} Bettor ${
            data.requested_qty > 1 ? 'tokens' : 'token'
          } for total amount $${(
            currentSubsToken.token_nav *
            data.requested_qty *
            (1 + currentSubsToken.platform_fees / 100)
          ).toFixed(2)} @Token Price of $${
            currentSubsToken.token_nav
          } will be initiated. Once Payment is cleared, Tokens will be alloted to your account in ${
            currentSubsToken.settlement_cycle
          } working days.`;
          setModalMessage(message);
        } else {
          console.log('message - - - -', btlTdaBankAccount);
          const message = `Please make a payment for the purchase of ${data.requested_qty} Bettor ${
            data.requested_qty > 1 ? 'tokens' : 'token'
          } for total amount $${(
            currentSubsToken.token_nav *
            data.requested_qty *
            (1 + currentSubsToken.platform_fees / 100)
          ).toFixed(2)} @Token Price of $${
            currentSubsToken.token_nav
          } on the below mentioned Account. Once Payment is cleared, Tokens will be alloted to your account in ${
            currentSubsToken.settlement_cycle
          } working days.`;
          setModalMessage(message);
        }
      }
    }
  });

  const handleConfirm = async () => {
    const contractDocFile = contractDoc.current.files[0];
    formData.signed_token_term_sheet = contractDocFile;
    console.log('subscription form data - - -', formData);
    const requestedQty = formData.requested_qty;
    try {
      console.log('current subs Token - ', currentSubsToken);

      const res = await subscriptionData();
      console.log('response from subscription data - - -', res);
      if (res) {
        // Adding check for Offline Payment - No ASTRA for offline payment
        if (formData.payment_method === 'OFFLINE') {
          enqueueSnackbar(
            `Your subscription request is received by the platform. Tokens will be credited to your account after successful payment.`,
            {
              variant: 'success'
            }
          );
          navigate('/subscription/investor-dashboard');
        } else {
          // create an ASTRA routine
          const postData = {
            latest_nav: currentSubsToken.token_nav,
            requested_qty: requestedQty,
            tda_fees:
              (currentSubsToken.platform_fees * (requestedQty * currentSubsToken.token_nav)) / 100,
            subscription_id: res.subscription_id,
            total_amount: (
              currentSubsToken.token_nav *
              requestedQty *
              (1 + currentSubsToken.platform_fees / 100)
            ).toFixed(2),
            purchase_type: 'BUY',
            platform_fees: currentSubsToken.platform_fees
          };
          console.log('creating ASTRA ROUTINE - - ');
          try {
            // const astraResponse = {"status":"success"};
            const astraResponse = await createAstraRoutine(postData);
            console.log('astraResponse - - - ', astraResponse);
            if (astraResponse.status === 'success') {
              enqueueSnackbar(
                `Payment towards purchase of ${postData.requested_qty} Bettor ${
                  postData.requested_qty > 1 ? 'tokens' : 'token'
                } $${(
                  postData.latest_nav *
                  postData.requested_qty *
                  (1 + postData.platform_fees / 100)
                ).toFixed(2)} @Token Price of $${
                  postData.latest_nav
                } is initiated. Once Payment is cleared, Tokens will be alloted to your account.`,
                {
                  variant: 'success'
                }
              );
              navigate('/subscription/investor-dashboard');
            } else {
              formik.setFieldValue('signed_token_term_sheet', '');
              enqueueSnackbar(`${astraResponse.message}`, {
                variant: 'error'
              });
              navigate('/subscription');
            }
          } catch (e) {
            formik.setFieldValue('signed_token_term_sheet', '');
            enqueueSnackbar('Something went wrong with Astra Payment', {
              variant: 'error'
            });
            console.log('inside catch block - navigating  error -  ', e);
            navigate('/subscription');
          }
        }
      } else {
        formik.setFieldValue('signed_token_term_sheet', '');
        enqueueSnackbar('Failed to create a subscription record for this purchase.', {
          variant: 'error'
        });
        navigate('/subscription/');
      }
      formik.setFieldValue('signed_token_term_sheet', '');
      // resetForm();
    } catch (error) {
      formik.setFieldValue('signed_token_term_sheet', '');
      throwErrorMessage(error);
      // enqueueSnackbar('Something went wrong.', {
      //   variant: 'error'
      // });
    }
  };

  const phoneNumberHandler = (value) => {
    let countryCode;
    const errors = {};
    setInvalidPhone(true);
    errors.investor_phone = 'Enter Valid Phone Number';
    const phoneNumber = parsePhoneNumber(value);
    if (phoneNumber) {
      countryCode = phoneNumber.country;
      if (countryCode) {
        const _phoneNumberValidityParse = parsePhoneNumber(value, countryCode);
        if (_phoneNumberValidityParse.isValid()) {
          formik.setFieldValue('investor_phone', _phoneNumberValidityParse.number);
          delete errors.investor_phone;
          console.log('phone details', _phoneNumberValidityParse);
          console.log('Phone Validity', phoneNumber.isValid());
          console.log('Phone Num', _phoneNumberValidityParse.number);
          setInvalidPhone(false);
        }
      }
    }
    return errors;
  };

  // eslint-disable-next-line no-unused-vars
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;
  return (
    <>
      {/* {!account && <WalletRequireOverlay />} */}
      <Page title="Subscription Form">
        <SubscriptionHead
          title={`${currentSubsToken?.token_name} | ${currentSubsToken?.token_symbol}`}
        />

        <Container sx={{ mt: 4 }}>
          {error && (
            <Box mb={4}>
              <Alert severity="error">{error}</Alert>
            </Box>
          )}

          <Card>
            <CardContent sx={{ m: 4, fontFamily: 'BeVietnamPro' }}>
              {isLoading || !account || !fetchedData ? (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    my: 4
                  }}
                >
                  <CircularProgress size={48} />
                  <Typography
                    variant="subtitle1"
                    style={{ marginTop: '8px', fontFamily: 'BeVietnamPro' }}
                  >
                    Loading Investor Details...
                  </Typography>
                </Box>
              ) : (
                <FormikProvider value={formik} validator={() => ({})}>
                  <Form
                    autoComplete="off"
                    onSubmit={handleSubmit}
                    sx={{ fontFamily: 'BeVietnamPro' }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        // alignItems: 'center',
                        // justifyContent: 'center',
                        my: 4
                      }}
                    >
                      {currentSubsToken && <InvestorTokenTable {...currentSubsToken} />}
                    </Box>
                    {disableSubmit && (
                      <Box mb={4} sx={{ fontFamily: 'BeVietnamPro' }}>
                        <Alert severity="warning">
                          <AlertTitle>Subscription Currently Unavailable</AlertTitle>
                          {currentSubsToken.token_name} is under issuance at the moment. Please try
                          again later to subscribe.
                        </Alert>
                      </Box>
                    )}

                    <Card
                      sx={{
                        boxShadow: 'none',
                        border: '1px solid #BBBBBB',
                        borderRadius: '0px',
                        px: 4,
                        py: 1
                      }}
                    >
                      <Typography
                        sx={{
                          mt: 2,
                          fontSize: '1.25rem',
                          fontWeight: 'bold',
                          fontFamily: 'BeVietnamPro'
                        }}
                      >
                        Invest
                      </Typography>
                      <Grid container spacing={3} sx={{ width: '100%', py: 2 }}>
                        {/* {JSON.stringify(errors)} */}

                        <Grid item lg={6} md={6} xs={12} sm={12}>
                          <FormLabel sx={{ fontFamily: 'BeVietnamPro' }}>Investor Type</FormLabel>

                          <FormControl size="small" variant="outlined" fullWidth sx={{ mt: 1.5 }}>
                            <Select
                              // inputProps={{ readOnly: isTokenSubscribedByInvestor }}
                              inputProps={{ readOnly: true }}
                              {...getFieldProps('investor_type')}
                              error={Boolean(touched.investor_type && errors.investor_type)}
                              helperText={touched.investor_type && errors.investor_type}
                              IconComponent={DropdownIcon}
                            >
                              <MenuItem key="INDIVIDUAL" value="INDIVIDUAL">
                                Individual
                              </MenuItem>
                              <MenuItem key="CORPORATE" value="CORPORATE">
                                Corporate
                              </MenuItem>
                            </Select>
                            <FormHelperText sx={{ color: '#d32f2f' }}>
                              {touched.investor_type && errors.investor_type}
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                        {/* {account && (
                          <Grid item lg={6} md={6} xs={12}>
                            <FormLabel>Settlement Account</FormLabel>
                            <TextField
                              value={ethToXdcAddress(account)}
                              inputProps={{ readOnly: true }}
                              sx={{ mt: 1.5 }}
                              fullWidth
                              size="small"
                              autoComplete="Eth Address"
                              type="text"
                            />
                          </Grid>
                        )} */}
                        <Grid item lg={6} md={6} xs={12}>
                          <FormLabel>Name</FormLabel>
                          <TextField
                            // inputProps={{ readOnly: isTokenSubscribedByInvestor }}
                            inputProps={{ readOnly: true }}
                            sx={{ mt: 1.5 }}
                            fullWidth
                            size="small"
                            autoComplete="Name"
                            type="text"
                            // placeholder="Enter Your Name"
                            {...getFieldProps('investor_name')}
                            error={Boolean(touched.investor_name && errors.investor_name)}
                            helperText={touched.investor_name && errors.investor_name}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} xs={12}>
                          <FormLabel>Email</FormLabel>
                          <TextField
                            // inputProps={{ readOnly: isTokenSubscribedByInvestor }}
                            inputProps={{ readOnly: true }}
                            sx={{ mt: 1.5 }}
                            fullWidth
                            size="small"
                            autoComplete="email"
                            type="email"
                            {...getFieldProps('investor_email')}
                            error={Boolean(touched.investor_email && errors.investor_email)}
                            helperText={touched.investor_email && errors.investor_email}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} xs={12} sm={12}>
                          <FormLabel>Country Code</FormLabel>

                          <FormControl size="small" variant="outlined" fullWidth sx={{ mt: 1.5 }}>
                            <Select
                              // inputProps={{ readOnly: isTokenSubscribedByInvestor }}
                              inputProps={{ readOnly: true }}
                              IconComponent={DropdownIcon}
                              {...getFieldProps('investor_country_code')}
                              error={Boolean(
                                touched.investor_country_code && errors.investor_country_code
                              )}
                              helperText={
                                touched.investor_country_code && errors.investor_country_code
                              }
                            >
                              {countries &&
                                countries.map((country, index) => {
                                  return (
                                    <MenuItem key={country.id} value={country.id}>
                                      {country.country_name}
                                    </MenuItem>
                                  );
                                })}
                            </Select>
                            <FormHelperText sx={{ color: '#d32f2f' }}>
                              {touched.investor_country_code && errors.investor_country_code}
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                        <Grid item lg={6} md={6} xs={12}>
                          <FormLabel>Phone Number</FormLabel>

                          <MuiPhoneInput
                            // inputProps={{ readOnly: isTokenSubscribedByInvestor }}
                            inputProps={{ readOnly: true }}
                            value={investorPhone}
                            sx={{ mt: 1.5 }}
                            fullWidth
                            defaultCountry="us"
                            variant="outlined"
                            size="small"
                            {...getFieldProps('investor_phone')}
                            onChange={(value) => {
                              debounce(phoneNumberHandler)(value);
                            }}
                            error={Boolean(touched.investor_phone && errors.investor_phone)}
                            helperText={touched.investor_phone && errors.investor_phone}
                          />
                        </Grid>

                        <Grid item lg={6} md={6} xs={12}>
                          <FormLabel>Token Quantity</FormLabel>
                          <TextField
                            sx={{ mt: 1.5 }}
                            fullWidth
                            size="small"
                            autoComplete="Token Quantity"
                            type="number"
                            {...getFieldProps('requested_qty')}
                            error={Boolean(touched.requested_qty && errors.requested_qty)}
                            helperText={touched.requested_qty && errors.requested_qty}
                          />
                        </Grid>

                        {/* Dummy Field for Settlement Account */}

                        <Grid item lg={6} md={6} sm={12} xs={12} sx={{ pr: 1 }}>
                          <FormLabel>Upload Signed Token Purchase Agreement</FormLabel>
                          <TextField
                            // sx={{ mt: 1.5 }}
                            sx={{
                              mt: 1.5
                            }}
                            fullWidth
                            size="small"
                            autoComplete="off"
                            type="file"
                            inputRef={contractDoc}
                            inputProps={{ accept: '.pdf' }}
                            {...getFieldProps('signed_token_term_sheet')}
                            id="signed_token_term_sheet"
                            error={Boolean(
                              touched.signed_token_term_sheet && errors.signed_token_term_sheet
                            )}
                            helperText={
                              touched.signed_token_term_sheet && errors.signed_token_term_sheet
                            }
                          />
                          <Grid container sx={{ width: '100%', mt: 2 }}>
                            <Grid item lg={4} md={4} sm={4}>
                              <Typography
                                sx={{
                                  fontSize: '0.688rem',
                                  textAlign: 'left',
                                  color: '#7A7A7A'
                                }}
                              >
                                Maximum 20MB file size
                              </Typography>
                            </Grid>
                            <Grid item lg={8} md={8} sm={8}>
                              <Typography
                                sx={{
                                  fontSize: '0.688rem',
                                  textAlign: 'right',
                                  color: '#7A7A7A'
                                }}
                              >
                                Accepted File Type .pdf only
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item lg={6} md={6} xs={12} sm={12}>
                          <FormLabel>Sales Person</FormLabel>

                          <FormControl size="small" variant="outlined" fullWidth sx={{ mt: 1.5 }}>
                            <Select
                              {...getFieldProps('sales_person')}
                              IconComponent={DropdownIcon}
                              error={Boolean(touched.sales_person && errors.sales_person)}
                              helperText={touched.sales_person && errors.sales_person}
                            >
                              <MenuItem selected value="NONE">
                                <em>None</em>
                              </MenuItem>
                              {salesPersons?.map((person, index) => {
                                return (
                                  <MenuItem key={person.id} value={person.id}>
                                    {person.name}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                            <FormHelperText sx={{ color: '#d32f2f' }}>
                              {touched.sales_person && errors.sales_person}
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                        <Grid item lg={6} md={6} xs={12} sm={12}>
                          <FormLabel>Payment Mode</FormLabel>

                          <FormControl size="small" variant="outlined" fullWidth sx={{ mt: 1.5 }}>
                            <Select
                              IconComponent={DropdownIcon}
                              {...getFieldProps('payment_method')}
                              error={Boolean(touched.payment_method && errors.payment_method)}
                              helperText={touched.payment_method && errors.payment_method}
                            >
                              {paymentMethod &&
                                paymentMethod.map((method, index) => {
                                  return (
                                    <MenuItem key={method.id} value={method.payment_method}>
                                      {method.payment_method}
                                    </MenuItem>
                                  );
                                })}
                            </Select>
                            <FormHelperText sx={{ color: '#d32f2f' }}>
                              {touched.investor_country_code && errors.investor_country_code}
                            </FormHelperText>
                          </FormControl>
                        </Grid>

                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <FormGroup
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center' // Use alignItems to vertically center the elements
                            }}
                          >
                            <FormControlLabel
                              sx={{ margin: 0, mr: 1 }}
                              control={<Checkbox />}
                              label="I accept the"
                              {...getFieldProps('terms_conditions_accepted')}
                            />
                            <Typography
                              sx={{
                                margin: 0,
                                display: 'flex',
                                alignItems: 'center',
                                cursor: 'pointer',
                                color: 'blue',
                                textDecoration: 'underline',
                                fontFamily: 'BeVietnamPro'
                              }}
                              component="p"
                              onClick={() => {
                                window.open(currentSubsToken?.contract_doc_hash, '_blank');
                              }}
                              // onClick={handleOpenTermsAndCondition} // Disabling the onclick because we will get a new link
                            >
                              Terms and Conditions
                            </Typography>
                          </FormGroup>
                          {touched.terms_conditions_accepted &&
                            errors.terms_conditions_accepted && (
                              <FormHelperText sx={{ color: '#d32f2f', ml: 1.5 }}>
                                {errors.terms_conditions_accepted}
                              </FormHelperText>
                            )}
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        sx={{
                          width: '100%',
                          mt: 2,
                          p: 2,
                          display: 'flex',
                          flexDirection: 'column'
                          // justifyContent: 'flex-center'
                        }}
                      >
                        <Grid
                          item
                          lg={12}
                          md={12}
                          xs={12}
                          sx={{ display: 'flex', justifyContent: 'center' }}
                        >
                          <Button
                            sx={{
                              px: 3,
                              mr: 1,
                              width: '7.75rem',
                              height: '2.5rem',
                              backgroundColor: '#000',
                              fontFamily: 'BeVietnamPro',
                              color: '#FFF'
                            }}
                            variant="contained"
                            color="secondary"
                            onClick={() => {
                              navigate('/subscription');
                            }}
                          >
                            Cancel
                          </Button>
                          <LoadingButton
                            variant="contained"
                            sx={{
                              width: '7.5rem',
                              height: '2.5rem',
                              backgroundColor: '#007DFF',
                              fontFamily: 'BeVietnamPro',
                              color: '#FFF'
                            }}
                            type="submit"
                            loadingPosition="start"
                            loading={isSubmitting}
                            disabled={disableSubmit}
                          >
                            Submit
                          </LoadingButton>
                        </Grid>
                      </Grid>
                    </Card>

                    <FocusError />
                  </Form>
                </FormikProvider>
              )}

              {/* <KYCModal
                service={sessionType}
                sessionId={sessionId}
                kycModalOpen={kycModalOpen}
                closeKycModal={closeKycModal}
              /> */}
              <PaymentModal open={openPaymentModal} handleClose={handleClosePaymentModal} />
              <TermsAndConditionsmodal
                open={showTermsAndCondition}
                handleClose={handleCloseTermsAndCondition}
              />
            </CardContent>
          </Card>
          <ConfirmationModal
            open={isModalOpen}
            handleClose={() => setIsModalOpen(false)}
            handleConfirm={handleConfirm}
          />
        </Container>
      </Page>
    </>
  );
};

export default SubscriptionForm;
