import Page from 'components/Page';
import React from 'react';
import {
  Card,
  Container,
  CardContent,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid
} from '@mui/material';
import Breadcrumbs from 'components/Breadcrumbs';
import Holding from 'components/masterData/Investor/Holding';
import OpenTransaction from 'components/masterData/Investor/OpenTransaction';
import InvestorIssuanceEscrowTable from 'components/subscritiption/InvestorIssuanceEscrowTable';
import InvestorRedemptionEscrowTable from 'components/subscritiption/InvestorRedemptionEscrowTable';
import InvestorViewAllotment from 'components/subscritiption/InvestorViewAllotment';
import InvestorViewRedemption from 'components/subscritiption/InvestorViewRedemptions';
import SubscriptionHead from 'components/subscritiption/SubscriptionHead';
import { ReactComponent as ExpandMoreIcon } from '../../assets/images/caratDown.svg';
import BTLChart from 'components/masterData/Investor/BTLChart';
import InvestorReportsDownload from 'components/subscritiption/InvestorReportsDownload';

const InvestorDashboard = () => {
  return (
    <>
      <Page title="My Investments">
        <SubscriptionHead title="My Investments" />
        <Container>
          {/* <Breadcrumbs pageHead="My Investments" /> */}
          <Card sx={{ mt: 2, background: `linear-gradient(180deg,  #FFF  10%,#EBEBEB 90%)` }}>
            <BTLChart />
          </Card>
          <Card sx={{ mt: 2 }}>
            <OpenTransaction />
          </Card>

          {/* Holding/Allotments */}
          <Accordion
            sx={{
              boxShadow: 'none',
              border: '1px solid #D2D2D2',
              borderRadius: '6px',
              px: 4,
              py: 1,
              mt: 4,
              background: `linear-gradient(180deg,  #FFF  10%,#EBEBEB 90%)`
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography
                variant="h4"
                sx={{
                  fontSize: '1.125rem',
                  fontWeight: 'bold'
                }}
              >
                Holdings
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <InvestorViewAllotment />
            </AccordionDetails>
          </Accordion>

          <Accordion
            sx={{
              boxShadow: 'none',
              border: '1px solid #D2D2D2',
              borderRadius: '6px',
              px: 4,
              py: 1,
              mt: 4,
              background: `linear-gradient(180deg,  #FFF  10%,#EBEBEB 90%)`
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography
                variant="h4"
                sx={{
                  fontSize: '1.125rem',
                  fontWeight: 'bold'
                }}
              >
                Redemptions
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <InvestorViewRedemption />
            </AccordionDetails>
          </Accordion>

          <Accordion
            sx={{
              boxShadow: 'none',
              border: '1px solid #D2D2D2',
              borderRadius: '6px',
              px: 4,
              py: 1,
              mt: 4,
              background: `linear-gradient(180deg,  #FFF  10%,#EBEBEB 90%)`
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography
                variant="h4"
                sx={{
                  fontSize: '1.125rem',
                  fontWeight: 'bold'
                }}
              >
                Transaction Report
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <InvestorReportsDownload />
            </AccordionDetails>
          </Accordion>
        </Container>
      </Page>
    </>
  );
};

export default InvestorDashboard;
