import * as Yup from 'yup';
import { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import CloseIcon from '@mui/icons-material/Close';
import Modal from '@mui/material/Modal';
import CircularProgress from '@material-ui/core/CircularProgress';
import { userTokenPersistence } from 'persistence';
import {
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Alert,
  Box,
  FormLabel,
  Typography,
  Button,
  Card
} from '@mui/material';

import { LoadingButton } from '@mui/lab';

import { useAuthState, useAppState, useWeb3, useMagicState } from 'state';
import { check } from 'prettier';
import MagicLinkModal from './MagicLinkModal';

const LoginForm = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(null);
  const [userEmail, setUserEmail] = useState('');
  const [openOtpModal, setOpenOtpModal] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const { otpVerificationComplete, connectMagicWallet } = useWeb3();
  const otpLaunchModalRef = useRef(null);
  const { submitLoginForm, setSubmitLoginForm } = useMagicState();
  const [userDetails, setUserDetails] = useState(null);
  const inputRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null)
  ];
  const [tryCount, setTryCount] = useState(0);
  const [otp, setOtp] = useState(['', '', '', '', '', '']);
  const LoginSchema = Yup.object().shape({
    username: Yup.string().required('Username is required'),
    password: Yup.string().required('Password is required')
  });

  const navigate = useNavigate();
  const { login, processLogin } = useAuthState();
  const { throwErrorMessage } = useAppState();

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
      remember: true
    },
    validationSchema: LoginSchema,
    onSubmit: async ({ username, password }) => {
      try {
        setSubmitting(true);
        setError(null);
        const res = await login(username.toLowerCase(), password);
        if (res?.user?.parent_xinfin_account?.role === 'INVESTOR') {
          setTryCount(0);
          setOtp(['', '', '', '', '', '']);
          setSubmitLoginForm(true);
          setUserDetails(res);
          setUserEmail(res?.user?.email);
          await connectMagicWallet(res.user?.email, otpLaunchModalRef, inputRefs);
        } else {
          processLogin(res);
        }
      } catch (e) {
        setSubmitting(false);
        setSubmitLoginForm(false);
        const errorMessage = await throwErrorMessage(e);
        setError(errorMessage);
      }
    }
  });

  useEffect(() => {
    if (otpVerificationComplete && userDetails) {
      processLogin(userDetails);
      formik.resetForm();
    }
  }, [otpVerificationComplete]);

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <>
      <Box sx={{ padding: 4 }}>
        <FormikProvider value={formik}>
          <Form className="dark-theme" autoComplete="off" noValidate onSubmit={handleSubmit}>
            {error && (
              <Box mb={4}>
                <Alert severity="error">{error}</Alert>
              </Box>
            )}
            <Stack spacing={3}>
              <FormLabel>Username</FormLabel>
              <TextField
                className="dark-bg"
                fullWidth
                autoComplete="username"
                id="username"
                type="text"
                placeholder="Enter username"
                {...getFieldProps('username')}
                error={Boolean(touched.username && errors.username)}
                helperText={touched.username && errors.username}
              />
              <FormLabel>Password</FormLabel>

              <TextField
                fullWidth
                autoComplete="current-password"
                type={showPassword ? 'text' : 'password'}
                placeholder="Enter Your Password"
                id="password"
                sx={{
                  outline: 'none'
                }}
                {...getFieldProps('password')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleShowPassword} edge="end">
                        <Icon
                          style={{ color: '#FFF' }}
                          icon={showPassword ? eyeFill : eyeOffFill}
                        />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                error={Boolean(touched.password && errors.password)}
                helperText={touched.password && errors.password}
              />
            </Stack>

            {/* <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <FormControlLabel
            control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
            label="Remember me"
          />
        </Stack> */}

            <LoadingButton
              fullWidth
              size="large"
              sx={{ mt: 4, borderRadius: '60px' }}
              type="submit"
              variant="gradient"
              loadingPosition="start"
              loading={isSubmitting || submitLoginForm}
              id="login-button"
            >
              <Typography sx={{ fontSize: '18px', fontFamily: 'Hind' }}>Login</Typography>
            </LoadingButton>
          </Form>

          <Button
            ref={otpLaunchModalRef}
            onClick={() => {
              setOpenOtpModal(!openOtpModal);
              setSubmitLoginForm(false);
            }}
            sx={{ display: 'none' }}
          >
            Open modal
          </Button>
          <MagicLinkModal
            openModal={openOtpModal}
            userEmail={userEmail}
            setOpenOtpModal={() => setOpenOtpModal()}
          />
        </FormikProvider>
      </Box>
      <Box
        sx={{
          mx: 'auto',
          p: 1,
          textAlign: 'center',
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'row'
        }}
      >
        <Button
          sx={{
            fontFamily: 'Cy Semibold',
            fontWeight: 600,
            fontSize: '15px',
            color: '#10C5FF',
            '&:disabled': {
              color: '#9CB3C9' // Light gray or any other visible color
            }
          }}
          disabled={submitting || submitLoginForm}
          onClick={() => {
            setSubmitting(false);
            setSubmitLoginForm(false);
            navigate('/request-forgot-password');
          }}
        >
          Forgot Password?
        </Button>

        <Button
          sx={{
            fontFamily: 'Cy Semibold',
            fontWeight: 600,
            fontSize: '15px',
            color: '#10C5FF',
            '&:disabled': {
              color: '#9CB3C9' // Light gray or any other visible color
            }
          }}
          disabled={submitting || submitLoginForm}
          onClick={() => {
            setSubmitting(false);
            setSubmitLoginForm(false);
            navigate('/sign-up');
          }}
        >
          Sign up as an Investor
        </Button>
      </Box>
    </>
  );
};

export default LoginForm;
