import { DesktopDatePicker, LocalizationProvider, LoadingButton } from '@mui/lab';
import PaginationTable from 'components/tables/PaginationTable';
import {
  Container,
  Alert,
  AlertTitle,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  Card,
  CardContent,
  IconButton,
  TextField,
  Grid,
  FormLabel,
  FormControl
} from '@mui/material';
import DateAdapter from '@mui/lab/AdapterDateFns';
import { format } from 'date-fns';
import { useAuthState, useBusinessEntityState, useHttpApi, useWeb3 } from 'state';
import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { saveAs } from 'file-saver';
import PDFDownloadIcon from 'assets/images/pdf-icon.png';
import CSVDownloadIcon from 'assets/images/csv-icon.png';

const InvestorReportsDownload = () => {
  const { user } = useAuthState();

  const { enqueueSnackbar } = useSnackbar();
  const { getTokenTransactionHistory, downloadCustomerTokentransactionsReport } = useHttpApi();
  const customer = {};
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [page, setPage] = useState(1);
  const queryParams = `?page=${page}&from=${fromDate || ''}&to=${toDate || ''}&investor=${
    customer?.investor_id
  }`;
  const [showError, setShowError] = useState(false);

  const downloadcustomerTransactionsReport = async (docFormat) => {
    if (!fromDate || !toDate) {
      return enqueueSnackbar('Please select a date range', { variant: 'error' });
    }
    if (!customer) {
      return enqueueSnackbar('Please select a customer', { variant: 'error' });
    }
    const reportResponse = await downloadCustomerTokentransactionsReport(
      `?start_date=${fromDate}&end_date=${toDate}&doc_format=${docFormat}`
    );
    const blob = new Blob([reportResponse], {
      type: docFormat === 'pdf' ? 'application/pdf' : 'text/csv'
    });
    saveAs(blob, `customer_transactions_report.${docFormat}`);
  };

  return (
    <Grid
      container
      spacing={3}
      sx={{
        flexDirection: 'row',
        flexWrap: 'wrap'
      }}
    >
      <Grid item lg={4} md={4} sm={4} xs={6}>
        <LocalizationProvider dateAdapter={DateAdapter}>
          <DesktopDatePicker
            label="From Date *"
            fullWidth
            size="small"
            inputFormat="MM-dd-yyyy"
            value={fromDate}
            onChange={(e) => {
              setFromDate(format(e, 'yyyy-MM-dd'));
            }}
            renderInput={(params) => (
              <TextField
                FormHelperTextProps={{ sx: { color: 'red' } }}
                fullWidth
                size="small"
                {...params}
                inputProps={{
                  ...params.inputProps,
                  readOnly: true // Prevent manual typing
                }}
                helperText={showError && !fromDate && 'Select a date'}
              />
            )}
          />
        </LocalizationProvider>
      </Grid>

      <Grid item lg={4} md={4} sm={4} xs={6}>
        <LocalizationProvider dateAdapter={DateAdapter}>
          <DesktopDatePicker
            label="To Date *"
            fullWidth
            size="small"
            inputFormat="MM-dd-yyyy"
            value={toDate}
            onChange={(e) => {
              setToDate(format(e, 'yyyy-MM-dd'));
            }}
            renderInput={(params) => (
              <TextField
                FormHelperTextProps={{ sx: { color: 'red' } }}
                fullWidth
                size="small"
                {...params}
                inputProps={{
                  ...params.inputProps,
                  readOnly: true // Prevent manual typing
                }}
                helperText={showError && !toDate && 'Select a date'}
              />
            )}
          />
        </LocalizationProvider>
      </Grid>

      <Grid item lg={4} md={4} sm={12} xs={12} container>
        <Box
          sx={{
            height: '2.5rem',
            width: '2.5rem',
            borderRadius: '0.2rem',
            backgroundColor: '#fff',
            color: '#000',
            boxShadow: 4,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            marginX: 1
          }}
          onClick={async () => {
            await downloadcustomerTransactionsReport('pdf');
          }}
        >
          <Box
            component="img"
            sx={{
              p: 1
            }}
            src={PDFDownloadIcon}
          />
        </Box>

        <Box
          sx={{
            height: '2.5rem',
            width: '2.5rem',
            borderRadius: '0.2rem',
            backgroundColor: '#fff',
            color: '#000',
            boxShadow: 4,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            marginX: 1
          }}
          onClick={async () => {
            await downloadcustomerTransactionsReport('csv');
          }}
        >
          <Box
            component="img"
            sx={{
              p: 1
            }}
            src={CSVDownloadIcon}
          />
        </Box>
      </Grid>

      {/* Download Icon */}
    </Grid>
  );
};

export default InvestorReportsDownload;
