import React, { useEffect, useState } from 'react';
import {
  Box,
  Container,
  Grid,
  Card,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  Typography
} from '@mui/material';
import TokenCard from 'components/issuerDashboard/TokenCard';
import Page from 'components/Page';
import EmptyDashboard from 'assets/images/emptyassets.png';
import { useAuthState, useCoreTableState, useBusinessEntityState } from 'state';
import { useNavigate } from 'react-router';
import CircularProgress from '@mui/material/CircularProgress';

const DataNotAvailable = () => {
  return (
    <>
      <Box
        sx={{
          height: '60vh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <img src={EmptyDashboard} alt="Empty Dashboard" />
        <Typography variant="p">No Token available</Typography>
      </Box>
    </>
  );
};

const TokenCardList = ({ item }) => {
  return (
    <>
      <TokenCard token={item} />
    </>
  );
};

const IssuerHome = () => {
  const existToken = true;
  const navigate = useNavigate();
  const { issueType, currencies, tokenStatus, fetchCurrencies } = useCoreTableState();
  const { tokens, setToken, setTokens, fetchTokens } = useBusinessEntityState();
  useEffect(() => {
    fetchCurrencies();
    fetchTokens();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [sortissueType, setSortissueType] = useState('');
  const [sortbyStatus, setSortbyStatus] = useState('');
  const [sortbyCurrency, setSortbyCurrency] = useState('');
  const [filter, setFilter] = useState('');
  const [filterTokens, setFilterTokens] = useState([]);
  useEffect(() => {
    const _tokens = tokens.filter(
      (item) =>
        item.issue_type_id.issue_type_name === sortissueType ||
        item.token_status_id.token_status === sortbyStatus ||
        item.token_currency.currency_code === sortbyCurrency
    );
    setFilterTokens(_tokens);
  }, [sortbyStatus, sortbyCurrency, sortissueType, tokens]);
  const Data = () => {
    return (
      <>
        <Grid container spacing={3} sx={{ mt: 0.2 }}>
          {console.log('filter tokens - - - - -', filterTokens)}
          {filterTokens.map((item, index) => (
            <Grid
              key={index}
              onClick={() => {
                setToken(item);
                navigate(`/home/token-dashboard`);
              }}
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
            >
              <TokenCardList item={item} />
            </Grid>
          ))}
        </Grid>
        {filterTokens.length === 0 && <DataNotAvailable />}
      </>
    );
  };

  return (
    <Page title="Issuer Dashboard">
      <Container sx={{ my: 2 }}>
        <Grid container>
          <Grid
            xs={6}
            sm={6}
            md={6}
            lg={6}
            sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
          >
            <Typography variant="h6">Tokens</Typography>
          </Grid>
          <Grid
            xs={6}
            sm={6}
            md={6}
            sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
          >
            {/* <Grid
              xs={6}
              sm={6}
              md={6}
              lg={6}
              sx={{ mx: 1, display: 'flex', justifyContent: 'flex-end' }}
            >
              <Typography variant="p">Filter by</Typography>
            </Grid> */}
            {/* <Grid xs={6} sm={6} md={6} lg={12}>
              <FormControl size="small" variant="outlined" fullWidth>
                <Select
                  displayEmpty
                  inputProps={{ 'aria-label': 'Without label' }}
                  value={filter}
                  onChange={(event) => {
                    setFilter(event.target.value);
                    setSortissueType('');
                    setSortbyStatus('');
                    setSortbyCurrency('');
                  }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value="IssueType">
                    <em>Issue Type</em>
                  </MenuItem>
                  <MenuItem value="Currency">
                    <em>Currency</em>
                  </MenuItem>
                  <MenuItem value="TokenStatus">
                    <em>Token Status</em>
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid> */}
            {filter === 'IssueType' && (
              <>
                <Grid
                  xs={6}
                  sm={6}
                  md={6}
                  lg={6}
                  sx={{ mx: 1, display: 'flex', justifyContent: 'flex-end' }}
                >
                  <Typography variant="p">Select </Typography>
                </Grid>
                <Grid xs={6} sm={6} md={6} lg={12}>
                  <FormControl size="small" variant="outlined" fullWidth>
                    <Select
                      displayEmpty
                      inputProps={{ 'aria-label': 'Without label' }}
                      onChange={(event) => {
                        setSortissueType(event.target.value);
                        setSortbyStatus('');
                        setSortbyCurrency('');
                      }}
                    >
                      <MenuItem value="">
                        <em>All</em>
                      </MenuItem>
                      {issueType.map((item, index) => (
                        <MenuItem key={index} value={item.issue_type_name}>
                          <em>{item.issue_type_name}</em>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </>
            )}
            {filter === 'Currency' && (
              <>
                <Grid
                  xs={6}
                  sm={6}
                  md={6}
                  lg={6}
                  sx={{ mx: 1, display: 'flex', justifyContent: 'flex-end' }}
                >
                  <Typography variant="p">Select</Typography>
                </Grid>
                <Grid xs={6} sm={6} md={6} lg={12}>
                  <FormControl size="small" variant="outlined" fullWidth>
                    <Select
                      displayEmpty
                      inputProps={{ 'aria-label': 'Without label' }}
                      onChange={(event) => {
                        setSortbyCurrency(event.target.value);
                        setSortbyStatus('');
                        setSortissueType('');
                      }}
                    >
                      <MenuItem value="">
                        <em>All</em>
                      </MenuItem>
                      {currencies &&
                        currencies.map((currency) => {
                          return (
                            <MenuItem key={currency.id} value={currency.currency_code}>
                              {currency.currency_code} | {currency.currency_name}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </Grid>
              </>
            )}
            {filter === 'TokenStatus' && (
              <>
                <Grid
                  xs={6}
                  sm={6}
                  md={6}
                  lg={6}
                  sx={{ mx: 1, display: 'flex', justifyContent: 'flex-end' }}
                >
                  <Typography variant="p">Select </Typography>
                </Grid>

                <Grid xs={6} sm={6} md={6} lg={12}>
                  <FormControl size="small" variant="outlined" fullWidth>
                    <Select
                      displayEmpty
                      inputProps={{ 'aria-label': 'Without label' }}
                      onChange={(event) => {
                        setSortbyStatus(event.target.value);
                        setSortissueType('');
                        setSortbyCurrency('');
                      }}
                    >
                      <MenuItem value="">
                        <em>All</em>
                      </MenuItem>
                      {tokenStatus.map((item, index) => (
                        <MenuItem key={index} value={item.token_status}>
                          <em>{item.token_status}</em>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>

        {/* if sortType not selected show all token else show sorted Data */}
        {tokens ? (
          <>
            {tokens.length !== 0 ? (
              <>
                {!sortissueType && !sortbyCurrency && !sortbyStatus ? (
                  <Grid container spacing={3} sx={{ mt: 0.2 }}>
                    {tokens.map((item, index) => (
                      <Grid
                        key={index}
                        onClick={() => {
                          setToken(item);
                          navigate(`/home/token-dashboard`);
                        }}
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        lg={3}
                      >
                        <TokenCardList item={item} />
                      </Grid>
                    ))}
                  </Grid>
                ) : (
                  <Data />
                )}
              </>
            ) : (
              <DataNotAvailable />
            )}
          </>
        ) : (
          <>
            <Box sx={{ display: 'flex' }}>
              <CircularProgress />
            </Box>
          </>
        )}
      </Container>
    </Page>
  );
};

export default IssuerHome;
