import { Box } from '@mui/material';

const EventBanner = ({ bannerImg }) => {
  return (
    <Box
      sx={{
        height: '65vh',
        width: '40vw',
        pt: '12%',
        display: 'flex', // Use flexbox to center the image inside the box
        justifyContent: 'center', // Center horizontally
        alignItems: 'center' // Center vertically
      }}
    >
      {bannerImg && (
        <Box
          component="img"
          sx={{
            width: '100%',
            height: '100%',
            objectFit: 'contain' // Ensure image fits without stretching
          }}
          src={bannerImg}
        />
      )}
    </Box>
  );
};

export default EventBanner;
