import { Button, Card, CardContent, Grid, TextField, Typography } from '@mui/material';
import LoadingActionButton from 'helpers/LoadingActionButton';
import { useSnackbar } from 'notistack';
import { useState, useRef } from 'react';
import { useHttpApi } from 'state';

// Reusable File Upload Component
const FileUploadInput = ({ label, onFileChange, error, helperText, inputRef }) => {
  return (
    <Grid container sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      <Grid item lg={11} md={10} sm={12} xs={12}>
        <TextField
          sx={{ mt: 1.5 }}
          fullWidth
          size="small"
          autoComplete="off"
          type="file"
          inputProps={{ accept: '.jpg,.jpeg,.png' }}
          onChange={onFileChange}
          error={Boolean(error)}
          helperText={helperText}
          inputRef={inputRef} // Attach ref to the input
        />
        <Grid container sx={{ width: '100%', mt: 1 }}>
          <Grid item lg={4} md={4} sm={4}>
            <Typography sx={{ fontSize: '0.688rem', textAlign: 'left', color: '#161c2d' }}>
              Maximum 2MB file size
            </Typography>
          </Grid>
          <Grid item lg={8} md={8} sm={8}>
            <Typography sx={{ fontSize: '0.688rem', textAlign: 'right', color: '#8e8ea7' }}>
              Accepted File Type .jpg, .jpeg & .png only
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const RecentUpcomingEvents = () => {
  // States for file uploads and error messages
  const [recentFile, setRecentFile] = useState(null);
  const [upcomingFile, setUpcomingFile] = useState(null);
  const [recentError, setRecentError] = useState('');
  const [upcomingError, setUpcomingError] = useState('');

  // Refs for file inputs
  const recentFileInputRef = useRef(null);
  const upcomingFileInputRef = useRef(null);

  const { uploadBettingEvent } = useHttpApi();
  const { enqueueSnackbar } = useSnackbar();

  // File validation function
  const validateFile = (file, setter, errorSetter, inputRef) => {
    if (!file) return; // No file selected
    if (file.size > 2 * 1024 * 1024) {
      errorSetter('File size exceeds 2MB');
      setter(null); // Clear the file state
      inputRef.current.value = ''; // Clear the input field
      return;
    }
    if (!['image/jpeg', 'image/png', 'image/jpg'].includes(file.type)) {
      errorSetter('Only .jpg, .jpeg & .png files are allowed');
      setter(null); // Clear the file state
      inputRef.current.value = ''; // Clear the input field
      return;
    }
    setter(file); // Set the file if valid
    errorSetter(''); // Clear any error
  };

  // Handle file change for Recent Performance
  const handleRecentFileChange = (event) => {
    const file = event.target.files[0];
    validateFile(file, setRecentFile, setRecentError, recentFileInputRef);
  };

  // Handle file change for Upcoming Event
  const handleUpcomingFileChange = (event) => {
    const file = event.target.files[0];
    validateFile(file, setUpcomingFile, setUpcomingError, upcomingFileInputRef);
  };

  // Handle submit for Recent Performance
  const handleRecentSubmit = async () => {
    if (!recentFile) {
      setRecentError('Please select a file to upload');
      return;
    }

    const data = {
      recent_performance: recentFile
    };

    try {
      const response = await uploadBettingEvent(data);
      if (response.id) {
        enqueueSnackbar('Recent performance uploaded successfully', { variant: 'success' });
      }
    } catch (error) {
      console.log('error', error);
      enqueueSnackbar('Failed to upload recent performance', { variant: 'error' });
    }
    recentFileInputRef.current.value = '';
    setRecentFile(null); // Clear file after successful upload
    setRecentError(''); // Clear error after successful upload
  };

  // Handle submit for Upcoming Event
  const handleUpcomingSubmit = async () => {
    if (!upcomingFile) {
      setUpcomingError('Please select a file to upload');
      return;
    }
    const data = {
      upcoming_event: upcomingFile
    };

    try {
      const response = await uploadBettingEvent(data);
      if (response.id) {
        enqueueSnackbar('Upcoming Event uploaded successfully', { variant: 'success' });
      }
    } catch (error) {
      enqueueSnackbar('Failed to upload Upcoming Event', { variant: 'error' });
    }
    upcomingFileInputRef.current.value = '';
    setUpcomingFile(null); // Clear file after successful upload
    setUpcomingError(''); // Clear error after successful upload
  };

  return (
    <Card>
      <CardContent sx={{ m: 2 }}>
        {/* Recent Performance Section */}
        <Grid container justifyContent="center" alignItems="center" spacing={2}>
          <Grid item lg={3} md={3} sm={3} xs={3}>
            <Typography variant="h6" color="initial">
              Recent Performance
            </Typography>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <FileUploadInput
              label="Recent Performance Upload"
              onFileChange={handleRecentFileChange}
              error={recentError}
              helperText={recentError}
              inputRef={recentFileInputRef}
            />
          </Grid>
          <Grid item lg={3} md={3} sm={6} xs={6}>
            <LoadingActionButton
              loadingPosition="start"
              variant="gradient"
              sx={{ width: '7.75rem', height: '3rem' }}
              onClick={handleRecentSubmit}
            >
              Upload
            </LoadingActionButton>
          </Grid>
        </Grid>

        {/* Upcoming Event Section */}
        <Grid sx={{ mt: 2 }} container justifyContent="center" alignItems="center" spacing={2}>
          <Grid item lg={3} md={3} sm={3} xs={3}>
            <Typography variant="h6" color="initial">
              Upcoming Event
            </Typography>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <FileUploadInput
              label="Upcoming Event Upload"
              onFileChange={handleUpcomingFileChange}
              error={upcomingError}
              helperText={upcomingError}
              inputRef={upcomingFileInputRef}
            />
          </Grid>
          <Grid item lg={3} md={3} sm={6} xs={6}>
            <Button
              variant="gradient"
              sx={{ width: '7.75rem', height: '3rem' }}
              onClick={handleUpcomingSubmit}
            >
              Upload
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default RecentUpcomingEvents;
