import React, { useEffect, useState } from 'react';
import EnhancedTable from 'components/tables/EnhancedTable';
import { useBusinessEntityState } from 'state/useBusinessEntityState';
import IdentityInformationModal from 'components/issuerDashboard/IdentityInformationModal';
import AccountInformationModal from 'components/issuerDashboard/AccountInformationModal';
import MailComponent from 'components/issuerDashboard/MailComponent';
import { Box } from '@mui/material';
import AddressFieldTools from 'components/AddressFieldTools';
import OfflineCashTransactionModal from 'components/issuerDashboard/OfflineCashTransactionModal';
import { useAuthStateShared } from 'state';
import { onlyIssuer } from 'helpers/rbac';

export default function ViewRedemptionsTables() {
  const { reedemedTokens, fetchAllReddemedAllotmentsByToken } = useBusinessEntityState();
  const [data, setData] = useState(reedemedTokens);
  const [skipPageReset, setSkipPageReset] = React.useState(false);
  const { role } = useAuthStateShared();

  useEffect(() => {
    fetchAllReddemedAllotmentsByToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refreshAllotments = () => {
    fetchAllReddemedAllotmentsByToken();
  };

  const ActionButtons = ({ investor, paymentMethod, redemptionId }) => {
    return (
      <>
        <IdentityInformationModal investorId={investor.investor_id} />
        <AccountInformationModal investorId={investor.investor_id} />
        {paymentMethod === 'OFFLINE' && onlyIssuer(role) && (
          <OfflineCashTransactionModal recordId={redemptionId} type="REDEMPTION" />
        )}

        <MailComponent email={investor.investor_email_id} subject="" body="" />
      </>
    );
  };
  const headCells = [
    {
      accessor: 'investor.investor_name',
      Header: 'Investor',
      width: 110,
      show: true
    },
    {
      accessor: 'xinfin_account',
      Header: 'Blockchain Address',
      show: true,
      width: 250,
      Cell: ({ row: { original } }) => {
        return (
          <AddressFieldTools
            address={original.investor.xinfin_account}
            decimals={0}
            showCopyButton
            showInBlockExplorer
          />
        );
      }
    },
    {
      accessor: 'redemption_date',
      Header: 'Redemption Date',
      width: 200,
      show: true,
      Cell: ({ row: { original } }) => {
        return <>{original.redemption_date ? original.redemption_date : `-`}</>;
      }
    },
    {
      accessor: 'redeemed_qty',
      Header: 'Redeem Qty',
      width: 170,
      show: true
    },
    {
      accessor: 'token_price',
      Header: 'Token Price',
      width: 150,
      show: true
    },
    {
      accessor: 'trade_amount',
      Header: 'Trade Amount ($)',
      width: 200,
      show: true
    },
    {
      accessor: 'platform_fees_amount',
      Header: 'TDA Fees ($)',
      width: 200,
      show: true
    },
    {
      accessor: 'redemption_status_title',
      Header: 'Status',
      width: 200,
      show: true,
      Cell: ({ row: { original } }) => {
        return <>{original.redemption_status_title.replace('_', ' ')}</>;
      }
    },
    {
      accessor: 'actions',
      Header: 'Actions',
      width: 200,
      Cell: ({ row: { original } }) => {
        // console.log(original);
        return (
          <ActionButtons
            investor={original.investor}
            paymentMethod={original.payment_method ? original.payment_method : 'ONLINE'}
            redemptionId={original.id}
          />
        );
      },
      show: true
    }
  ];

  const updateMyData = (rowIndex, columnId, value) => {
    setSkipPageReset(true);
    setData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value
          };
        }
        return row;
      })
    );
  };

  return (
    <>
      <EnhancedTable
        tableTitle="ViewRedemptions"
        columns={headCells}
        data={reedemedTokens}
        setData={setData}
        updateMyData={updateMyData}
        skipPageReset={skipPageReset}
        refreshFunction={refreshAllotments}
      />
    </>
  );
}
