import { DesktopDatePicker, LocalizationProvider, LoadingButton } from '@mui/lab';
import PaginationTable from 'components/tables/PaginationTable';
import {
  Container,
  Card,
  CardContent,
  TextField,
  Grid,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormHelperText,
  Box,
  Typography
} from '@mui/material';
import DateAdapter from '@mui/lab/AdapterDateFns';
import { format } from 'date-fns';
import { useBusinessEntityState, useHttpApi } from 'state';
import React, { useState, useEffect } from 'react';
import PDFDownloadIcon from 'assets/images/pdf-icon.png';
import CSVDownloadIcon from 'assets/images/csv-icon.png';
import { useSnackbar } from 'notistack';
import { saveAs } from 'file-saver';

const CustomerTransactionReport = () => {
  const { enqueueSnackbar } = useSnackbar();

  const { getTokenTransactionHistory, downloadCustomerTokentransactionsReport } = useHttpApi();
  const { currentToken, allInvestors, fetchAllInvestors } = useBusinessEntityState();
  const [navData, setNavData] = useState([]);
  const [data, setData] = useState([]);
  const [skipPageReset, setSkipPageReset] = useState(false);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [customer, setCustomer] = useState('');
  const [page, setPage] = useState(1);
  const [totalUnrealizedPnl, setTotalUnrealizedPnl] = useState('');
  const [totalRealizedPnl, setTotalRealizedPnl] = useState('');
  const queryParams = `?page=${page}&from=${fromDate || ''}&to=${toDate || ''}&investor=${
    customer?.investor_id
  }`;
  const [totalCount, setTotalCount] = useState(0);
  const [showError, setShowError] = useState(false);

  const fetchCustomerTransactionHistory = async () => {
    if (!fromDate && !toDate && !customer) {
      setShowError(true);
      return;
    }
    const res = await getTokenTransactionHistory(currentToken?.token_id, queryParams);

    console.log('res', res);
    setData(res.results);
    setTotalCount(res.count);
    setTotalRealizedPnl(res?.total_realized_pnl);
  };

  const downloadcustomerTransactionsReport = async (docFormat) => {
    if (!fromDate || !toDate) {
      return enqueueSnackbar('Please select a date range', { variant: 'error' });
    }
    if (!customer) {
      return enqueueSnackbar('Please select a customer', { variant: 'error' });
    }
    const reportResponse = await downloadCustomerTokentransactionsReport(
      `?token_id=${currentToken?.token_id}&start_date=${fromDate}&end_date=${toDate}&customer_id=${customer?.investor_id}&doc_format=${docFormat}`
    );
    const blob = new Blob([reportResponse], {
      type: docFormat === 'pdf' ? 'application/pdf' : 'text/csv'
    });
    saveAs(blob, `customer_transactions_report.${docFormat}`);
  };

  // UseEffect

  useEffect(() => {
    if (fromDate && toDate && customer) {
      fetchCustomerTransactionHistory();
    }
  }, [page]);

  useEffect(() => {
    fetchAllInvestors();
  }, []);
  const headCells = [
    {
      accessor: 'transaction_date',
      Header: 'Transaction Date',
      show: true
    },

    {
      accessor: 'transaction_type',
      Header: 'Transaction Type',
      show: true
    },
    {
      accessor: 'transaction_token_price',
      Header: 'Transaction Price/Token ($)',
      show: true
    },
    {
      accessor: 'token_qty',
      Header: 'Quantity',
      show: true
    },
    // {
    //   accessor: 'nav',
    //   Header: 'Last Reset Price/Token ($)',
    //   show: true
    // },
    {
      accessor: 'sales_consideration',
      Header: 'Trade Value ($)',
      show: true
    },
    {
      accessor: 'fees_amount',
      Header: 'Platform Fees ($)',
      show: true
    },
    {
      accessor: 'total_transaction_amount_paid_by_investor',
      Header: 'Total Paid By Investor ($)',
      show: true
    },
    {
      accessor: 'total_transaction_amount_paid_to_investor',
      Header: 'Total Paid To Investor ($)',
      show: true
    },
    {
      accessor: 'investor_profit_or_loss_amount',
      Header: 'Investor Profit/Loss ($)',
      show: true
    }
  ];

  const updateMyData = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    setSkipPageReset(true);
    setData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value
          };
        }
        return row;
      })
    );
  };

  return (
    <Container sx={{ mt: 4 }}>
      <Card>
        <CardContent>
          {/* Filter */}
          <Grid
            container
            spacing={1}
            sx={{
              flexDirection: 'row',
              flexWrap: 'wrap'
            }}
          >
            <Grid item lg={3} md={4} sm={4} xs={6}>
              <LocalizationProvider dateAdapter={DateAdapter}>
                <DesktopDatePicker
                  label="From Date *"
                  fullWidth
                  size="small"
                  inputFormat="MM-dd-yyyy"
                  value={fromDate}
                  onChange={(e) => {
                    setFromDate(format(e, 'yyyy-MM-dd'));
                  }}
                  renderInput={(params) => (
                    <TextField
                      FormHelperTextProps={{ sx: { color: 'red' } }}
                      fullWidth
                      size="small"
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        readOnly: true // Prevent manual typing
                      }}
                      helperText={showError && !fromDate && 'Select a date'}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item lg={3} md={4} sm={4} xs={6}>
              <LocalizationProvider dateAdapter={DateAdapter}>
                <DesktopDatePicker
                  label="To Date *"
                  fullWidth
                  size="small"
                  inputFormat="MM-dd-yyyy"
                  value={toDate}
                  onChange={(e) => {
                    setToDate(format(e, 'yyyy-MM-dd'));
                  }}
                  renderInput={(params) => (
                    <TextField
                      FormHelperTextProps={{ sx: { color: 'red' } }}
                      fullWidth
                      size="small"
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        readOnly: true // Prevent manual typing
                      }}
                      helperText={showError && !toDate && 'Select a date'}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item lg={3} md={4} sm={4} xs={6}>
              <FormControl fullWidth size="small">
                <InputLabel id="customer-select-label">Customer</InputLabel>
                <Select
                  fullWidth
                  label="Customer *"
                  value={customer}
                  size="small"
                  placeholder="Select Customer"
                  onChange={(e) => {
                    console.log('🚀 ~ CustomerTransactionReport ~ e:', e);
                    setCustomer(e.target.value);
                  }}
                >
                  {allInvestors.map((investor) => {
                    return (
                      <MenuItem key={investor.investor_id} value={investor}>
                        {investor.investor_name}- {investor.investor_email_id}
                      </MenuItem>
                    );
                  })}
                </Select>
                {showError && !customer && (
                  <FormHelperText sx={{ color: 'red' }}>Select a customer</FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item lg={3} md={4} sm={12} xs={12} container>
              <LoadingButton
                disabled={!fromDate || !toDate || !customer}
                variant="gradient"
                loadingPosition="start"
                onClick={fetchCustomerTransactionHistory}
              >
                Filter
              </LoadingButton>

              <Box
                sx={{
                  height: '2.5rem',
                  width: '2.5rem',
                  borderRadius: '0.2rem',
                  backgroundColor: '#fff',
                  color: '#000',
                  boxShadow: 4,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer',
                  marginX: 1
                }}
                onClick={async () => {
                  await downloadcustomerTransactionsReport('pdf');
                }}
              >
                <Box
                  component="img"
                  sx={{
                    p: 1
                  }}
                  src={PDFDownloadIcon}
                />
              </Box>

              <Box
                sx={{
                  height: '2.5rem',
                  width: '2.5rem',
                  borderRadius: '0.2rem',
                  backgroundColor: '#fff',
                  color: '#000',
                  boxShadow: 4,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer',
                  marginX: 1
                }}
                onClick={async () => {
                  await downloadcustomerTransactionsReport('csv');
                }}
              >
                <Box
                  component="img"
                  sx={{
                    p: 1
                  }}
                  src={CSVDownloadIcon}
                />
              </Box>
            </Grid>

            {/* Download Icon */}
          </Grid>
          {/* Table */}
          {customer && (
            <Box sx={{ my: 3 }}>
              <Typography>
                <strong>Customer Name</strong> : {customer?.investor_name}
              </Typography>
              <Typography>
                <strong>Customer Email</strong> : {customer?.investor_email_id}
              </Typography>
              <Typography>
                <strong>Customer Phone No.</strong> : {customer?.investor_phone_no}
              </Typography>
              {data.length > 0 && (
                <Typography>
                  <strong>Realized P&L</strong> : ${totalRealizedPnl}
                </Typography>
              )}
            </Box>
          )}
          <PaginationTable
            handlePageUpdate={setPage}
            tableTitle=""
            columns={headCells}
            refreshFunction={fetchCustomerTransactionHistory}
            data={data}
            setData={setData}
            updateMyData={updateMyData}
            skipPageReset={skipPageReset}
            count={totalCount}
          />
        </CardContent>
      </Card>
    </Container>
  );
};

export default CustomerTransactionReport;
