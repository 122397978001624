import React, { useState } from 'react';
import Page from 'components/Page';
import {
  Container,
  Card,
  Grid,
  Box,
  CardContent,
  Typography,
  TextField,
  FormLabel,
  Button,
  Divider,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  Alert,
  List,
  ListItem,
  ListItemText,
  IconButton,
  InputAdornment,
  Stack
} from '@mui/material';
import Breadcrumbs from 'components/Breadcrumbs';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { useFormik, Form, FormikProvider } from 'formik';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
import { useAppState, useAuthState, useCoreTableState } from 'state';
import SubscriptionHead from 'components/subscritiption/SubscriptionHead';

const ChangePassword = () => {
  const { changePassword } = useAuthState();
  const { enqueueSnackbar } = useSnackbar();
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const { fetchOrganizations } = useCoreTableState();
  const { throwErrorMessage } = useAppState();
  const ChangePasswordSchema = Yup.object().shape({
    old_password: Yup.string().required('Old Password is required'),
    new_password: Yup.string()
      .required('New Password is required')
      .min(8, 'Password must be minimum 8 characters long')
      .notOneOf([Yup.ref('old_password'), null], 'New Password cannot be same as Old Password')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])[A-Za-z\d!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{8,}$/,
        'Must Contain atleast one Uppercase, one Lowercase, one Number and one special case Character'
      ),
    confirm_new_password: Yup.string()
      .oneOf([Yup.ref('new_password'), null], 'Passwords must match')
      .required('Confirm New Password is required')
  });
  const formik = useFormik({
    initialValues: {
      old_password: '',
      new_password: '',
      confirm_new_password: ''
    },
    validationSchema: ChangePasswordSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        setError(null);
        await changePassword(values);
        enqueueSnackbar('Password Changed Successfully', { variant: 'success' });
        resetForm();
        navigate('/login');
        fetchOrganizations();
      } catch (e) {
        const errorMessage = await throwErrorMessage(e);
        setError(errorMessage);
      }
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <>
      <Page title="">
        <SubscriptionHead title="Change Old Password" />
        <Container sx={{ my: 5 }}>
          {/* <Box sx={{ my: 2 }}>
            <Breadcrumbs aria-label="breadcrumb" pageHead="Change Old Password" />
          </Box> */}
          {/* <Box sx={{ my: 2 }}>
            <Typography
              sx={{
                textTransform: 'capitalize',
                fontWeight: 'bold',
                color: '#181C32',
                fontSize: '1.5rem'
              }}
            >
              Change Old Password
            </Typography>
          </Box> */}
          {error && (
            <Box mb={4}>
              <Alert
                severity="error"
                sx={{
                  textTransform: 'capitalize'
                }}
              >
                {error}
              </Alert>
            </Box>
          )}
          <Card>
            <Container>
              <FormikProvider value={formik}>
                <Form autoComplete="off" onSubmit={handleSubmit}>
                  <CardContent
                    sx={{
                      // m: 2,
                      my: 4,
                      mx: 2,
                      border: '1px solid #BBBBBB !important',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <Stack spacing={3} sx={{ width: '40%', mt: 1 }}>
                      <Grid item lg={12} md={12} sm={12} xs={12} sx={{ pr: 1 }}>
                        <Grid item lg={12} md={6} xs={12} sm={12}>
                          <FormLabel>Old Password</FormLabel>
                          <TextField
                            sx={{ mt: 1.5 }}
                            {...getFieldProps('old_password')}
                            error={Boolean(touched.old_password && errors.old_password)}
                            helperText={touched.old_password && errors.old_password}
                            fullWidth
                            size="small"
                            autoComplete="off"
                            placeholder="Enter Old Password"
                            type={showOldPassword ? 'text' : 'password'}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={() => {
                                      setShowOldPassword((show) => !show);
                                    }}
                                    edge="end"
                                  >
                                    <Icon
                                      style={{ color: '#000' }}
                                      icon={showOldPassword ? eyeFill : eyeOffFill}
                                    />
                                  </IconButton>
                                </InputAdornment>
                              )
                            }}
                          />
                        </Grid>
                        <Grid item lg={12} md={6} xs={12} sm={12} sx={{ mt: 2 }}>
                          <FormLabel>New Password</FormLabel>
                          <TextField
                            sx={{ mt: 1.5 }}
                            {...getFieldProps('new_password')}
                            error={Boolean(touched.new_password && errors.new_password)}
                            helperText={touched.new_password && errors.new_password}
                            fullWidth
                            size="small"
                            autoComplete="off"
                            type={showNewPassword ? 'text' : 'password'}
                            placeholder="Enter New Password"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={() => {
                                      setShowNewPassword((show) => !show);
                                    }}
                                    edge="end"
                                  >
                                    <Icon
                                      style={{ color: '#000' }}
                                      icon={showNewPassword ? eyeFill : eyeOffFill}
                                    />
                                  </IconButton>
                                </InputAdornment>
                              )
                            }}
                          />
                        </Grid>
                        <Grid item lg={12} md={6} xs={12} sm={12} sx={{ mt: 2 }}>
                          <FormLabel>New Password Confirmation</FormLabel>
                          <TextField
                            sx={{ mt: 1.5 }}
                            {...getFieldProps('confirm_new_password')}
                            error={Boolean(
                              touched.confirm_new_password && errors.confirm_new_password
                            )}
                            helperText={touched.confirm_new_password && errors.confirm_new_password}
                            fullWidth
                            size="small"
                            autoComplete="off"
                            type="password"
                            placeholder="Confirm New Password"
                          />
                        </Grid>
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12} sx={{ pr: 1 }}>
                        <Grid item lg={12} md={6} xs={12} sm={12}>
                          <Typography sx={{ fontWeight: 'bold' }}>
                            In order to protect your account, make sure your password is:
                          </Typography>
                          <List
                            sx={{
                              listStyleType: 'disc', // Defines the bullet style
                              listStylePosition: 'inside',
                              pl: 2,
                              '& li': {
                                '&::marker': {
                                  color: '#0C4D88'
                                }
                              }
                            }}
                          >
                            <ListItem sx={{ display: 'list-item', padding: 0 }}>
                              New password must be different from previously used old password
                            </ListItem>
                            <ListItem sx={{ display: 'list-item', padding: 0 }}>
                              At least 8 characters long
                            </ListItem>
                            <ListItem sx={{ display: 'list-item', padding: 0 }}>
                              Characters from 3 different groups (lowercase, uppercase, numbers,
                              special characters)
                            </ListItem>
                          </List>
                        </Grid>
                      </Grid>
                      <Box
                        sx={{
                          mt: 4,
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'center'
                        }}
                      >
                        <LoadingButton
                          type="submit"
                          variant="gradient"
                          fullWidth
                          size="large"
                          loadingPosition="start"
                          loading={isSubmitting}
                          sx={{
                            margin: 1,
                            px: 3,
                            width: '100%',
                            height: '2.5rem',
                            borderRadius: '60px'
                            // width: '187.47px'
                          }}
                        >
                          Change Password
                        </LoadingButton>
                      </Box>
                    </Stack>
                  </CardContent>
                </Form>
              </FormikProvider>
            </Container>
          </Card>
        </Container>
      </Page>
    </>
  );
};

export default ChangePassword;
