import { useBusinessEntityState, useHttpApi } from 'state';
import TokenTable from './TokenTable';
import {
  Container,
  Alert,
  AlertTitle,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  Card,
  CardContent,
  IconButton
} from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import CreateIcon from '@mui/icons-material/Create';
import Breadcrumbs from 'components/Breadcrumbs';
import { useEffect, useState } from 'react';
import CopyAddress from 'components/CopyAddress';
import { ethToXdcAddress } from 'helpers/web3';
import EnhancedTable from 'components/tables/EnhancedTable';
import AccountInformationModal from './AccountInformationModal';
import IdentityInformationModal from './IdentityInformationModal';
import { Link } from 'react-router-dom';
import AddressFieldTools from 'components/AddressFieldTools';
import MailComponent from './MailComponent';
import RolloverModal from './RolloverModal';
import PaginationTable from 'components/tables/PaginationTable';

const Rollover = () => {
  const { getAllInvestorsByToken, getAllInvestorAndRolloverStatus } = useHttpApi();
  const [data, setData] = useState([]);
  const { currentToken } = useBusinessEntityState();
  const [skipPageReset, setSkipPageReset] = useState(false);
  const [page, setPage] = useState(1);
  const queryParams = `?page=${page}`;
  const [totalCount, setTotalCount] = useState(0);
  const fetchInvestorAndRolloverStatus = async () => {
    const res = await getAllInvestorAndRolloverStatus(queryParams);
    setTotalCount(res.count);
    // const res = await getAllInvestorsByToken(currentToken?.token_id);
    setData(res.results);
  };
  useEffect(() => {
    fetchInvestorAndRolloverStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { currentToken: token } = useBusinessEntityState();

  const headCells = [
    {
      accessor: 'investor_name',
      Header: 'Investor Name',
      show: true
    },
    {
      accessor: 'xinfin_account',
      Header: 'Blockchain Address',
      show: true,
      width: 200,
      Cell: ({ row: { original } }) => {
        return (
          <AddressFieldTools
            address={original.xinfin_account}
            decimals={0}
            showCopyButton
            showInBlockExplorer
          />
        );
      }
    },

    {
      accessor: 'investor_country_code.country_name',
      Header: 'Country',
      show: true,
      width: 100
    },
    {
      accessor: 'actions',
      Header: 'Actions',
      Cell: ({ row: { original } }) => {
        return <ActionButtons row={original} />;
      },
      show: true
    }
  ];

  const ActionButtons = ({ row }) => {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <RolloverModal
          refetch={fetchInvestorAndRolloverStatus}
          rolloverInfomation={{
            investor_id: row.investor_id,
            investor_name: row.investor_name,
            ...row.rollover_data
          }}
        />
      </Box>
    );
  };

  const updateMyData = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    setSkipPageReset(true);
    setData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value
          };
        }
        return row;
      })
    );
  };

  return (
    <Container>
      <Breadcrumbs aria-label="breadcrumb" pageHead="" />
      <TokenTable currToken={token} />
      <Box sx={{ mt: 4 }}>
        <Box
          sx={{
            mt: 2,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Typography
            variant="body2"
            color="initial"
            sx={{
              mt: 4,
              mb: 2,
              fontSize: '1.125rem',
              fontWeight: 'bold'
            }}
          >
            Process Profits Rollover
          </Typography>
        </Box>
        <Card>
          <CardContent>
            <PaginationTable
              handlePageUpdate={setPage}
              tableTitle="Investor Rollover Process"
              columns={headCells}
              refreshFunction={fetchInvestorAndRolloverStatus}
              data={data}
              setData={setData}
              updateMyData={updateMyData}
              skipPageReset={skipPageReset}
              count={totalCount}
            />
          </CardContent>
        </Card>
      </Box>
    </Container>
  );
};

export default Rollover;
