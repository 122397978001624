import { styled, Typography, Box, Grid, Button, Card } from '@mui/material';
import { useEffect } from 'react';
import Page from '../components/Page';
import { LoginForm } from '../components/authentication/login';
import loginImage from '../assets/images/loginimage.png';
import Logo from 'components/Logo';
import { useNavigate } from 'react-router-dom';
import { userTokenPersistence } from 'persistence';
import XinfinLogin from 'components/XinfinLogin';
import { useCoreTableState, useSubscriptionState } from 'state';

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    background: 'transparent',
    height: '100vh'
  }
}));

const Login = () => {
  const navigate = useNavigate();
  const userToken = userTokenPersistence.get();

  useEffect(() => {
    if (userToken) {
      navigate('/home');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToken]);

  // const { orgLogo } = useSubscriptionState();
  const { fetchOrganizations, orgnizations } = useCoreTableState();

  useEffect(() => {
    fetchOrganizations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card
      sx={{
        mx: 'auto',
        backgroundColor: '#0D0D5E',
        width: '483.38px',
        height: 'auto',
        borderRadius: '8px',
        py: 4
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          mb: '2rem',
          marginTop: 2
          // marginTop: '213.97px'
        }}
      >
        {orgnizations[0] && (
          <Box component="img" src={orgnizations[0].logo} sx={{ height: '6rem', width: 'auto' }} />
        )}
      </Box>

      <LoginForm />

      {/* <XinfinLogin /> */}
    </Card>
  );
};

export default Login;
