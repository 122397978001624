import { DesktopDatePicker, LocalizationProvider, LoadingButton } from '@mui/lab';
import PaginationTable from 'components/tables/PaginationTable';
import {
  Container,
  Alert,
  AlertTitle,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  Card,
  CardContent,
  IconButton,
  TextField,
  Grid,
  FormLabel,
  FormControl
} from '@mui/material';
import DateAdapter from '@mui/lab/AdapterDateFns';
import { format } from 'date-fns';
import { useBusinessEntityState, useHttpApi, useWeb3 } from 'state';
import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { saveAs } from 'file-saver';
import PDFDownloadIcon from 'assets/images/pdf-icon.png';
import CSVDownloadIcon from 'assets/images/csv-icon.png';

const BTLTransactionReport = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { getTokenTransactionHistory, downloadBtlTokenTransactionsReport } = useHttpApi();
  const { currentToken } = useBusinessEntityState();
  const [data, setData] = useState([]);
  const [skipPageReset, setSkipPageReset] = useState(false);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [page, setPage] = useState(1);
  const queryParams = `?page=${page}&from=${fromDate || ''}&to=${toDate || ''}`;
  const [totalCount, setTotalCount] = useState(0);
  const [showError, setShowError] = useState(false);
  const { getBalanceOf, getTotalSupply } = useWeb3();
  const [totalTokenSoldCount, setTotalTokenSoldCount] = useState(0);
  const [totalUnrealizedPnl, setTotalUnrealizedPnl] = useState('');
  const [totalRealizedPnl, setTotalRealizedPnl] = useState('');

  const fetchTotalTokenSoldCount = async () => {
    if (currentToken.token_status_id.token_status !== 'NEW') {
      const balance = await getBalanceOf(currentToken?.deployment_address);
      const totalSupply = await getTotalSupply(currentToken?.deployment_address);
      setTotalTokenSoldCount(totalSupply - balance);
    }
  };

  const fetchBTLTransactionHistory = async () => {
    if (!fromDate || !toDate) {
      setShowError(true);
      return;
    }
    const res = await getTokenTransactionHistory(currentToken?.token_id, queryParams);
    setData(res.results);
    setTotalCount(res.count);
    setTotalRealizedPnl(res.total_realized_pnl);
  };

  const downloadBtlTransactionsReport = async (docFormat) => {
    if (!fromDate || !toDate) {
      return enqueueSnackbar('Please select a date range', { variant: 'error' });
    }
    const reportResponse = await downloadBtlTokenTransactionsReport(
      `?token_id=${currentToken?.token_id}&start_date=${fromDate}&end_date=${toDate}&doc_format=${docFormat}`
    );
    const blob = new Blob([reportResponse], {
      type: docFormat === 'pdf' ? 'application/pdf' : 'text/csv'
    });
    saveAs(blob, `BTL_transactions_report.${docFormat}`);
  };

  // UseEffects

  useEffect(() => {
    if (fromDate && toDate) {
      fetchBTLTransactionHistory();
    }
  }, [page]);

  useEffect(() => {
    fetchTotalTokenSoldCount();
  }, []);

  const headCells = [
    {
      accessor: 'investor.investor_name',
      Header: 'Customer Name',
      show: true
    },
    {
      accessor: 'investor.investor_email_id',
      Header: 'Email',
      show: true
    },
    {
      accessor: 'investor.investor_phone_no',
      Header: 'Phone No',
      show: true
    },
    {
      accessor: 'transaction_date',
      Header: 'Transaction Date',
      show: true
    },

    {
      accessor: 'transaction_type',
      Header: 'Transaction Type',
      show: true
    },
    {
      accessor: 'transaction_token_price',
      Header: 'Transaction Price/Token ($)',
      show: true
    },
    {
      accessor: 'token_qty',
      Header: 'Quantity',
      show: true
    },
    // {
    //   accessor: 'nav',
    //   Header: 'Last Reset Price/Token ($)',
    //   show: true
    // },
    {
      accessor: 'sales_consideration',
      Header: 'Trade Value ($)',
      show: true
    },
    {
      accessor: 'fees_amount',
      Header: 'Platform Fees ($)',
      show: true
    },
    {
      accessor: 'total_transaction_amount_paid_by_investor',
      Header: 'Total Paid By Investor ($)',
      show: true
    },
    {
      accessor: 'total_transaction_amount_paid_to_investor',
      Header: 'Total Paid To Investor ($)',
      show: true
    },
    {
      accessor: 'investor_profit_or_loss_amount',
      Header: 'Investor Profit/Loss ($)',
      show: true
    }
  ];

  const updateMyData = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    setSkipPageReset(true);
    setData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value
          };
        }
        return row;
      })
    );
  };

  return (
    <Container sx={{ mt: 4 }}>
      <Card>
        <CardContent>
          {/* Filter */}
          <Grid
            container
            spacing={1}
            sx={{ width: '100%', flexDirection: 'row', flexWrap: 'wrap' }}
          >
            <Grid item lg={3} md={4} sm={8} xs={8}>
              <LocalizationProvider dateAdapter={DateAdapter}>
                <DesktopDatePicker
                  label="From Date *"
                  fullWidth
                  size="small"
                  inputFormat="MM-dd-yyyy"
                  value={fromDate}
                  onChange={(e) => {
                    setFromDate(format(e, 'yyyy-MM-dd'));
                  }}
                  renderInput={(params) => (
                    <TextField
                      FormHelperTextProps={{ sx: { color: 'red' } }}
                      fullWidth
                      size="small"
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        readOnly: true // Prevent manual typing
                      }}
                      helperText={showError && !fromDate && 'Select a date'}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item lg={3} md={4} sm={8} xs={8}>
              <LocalizationProvider dateAdapter={DateAdapter}>
                <DesktopDatePicker
                  label="To Date *"
                  fullWidth
                  size="small"
                  inputFormat="MM-dd-yyyy"
                  value={toDate}
                  onChange={(e) => {
                    setToDate(format(e, 'yyyy-MM-dd'));
                  }}
                  renderInput={(params) => (
                    <TextField
                      FormHelperTextProps={{ sx: { color: 'red' } }}
                      fullWidth
                      size="small"
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        readOnly: true // Prevent manual typing
                      }}
                      helperText={showError && !toDate && 'Select a date'}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item lg={3} md={4} sm={12} xs={12} container>
              <LoadingButton
                disabled={!fromDate || !toDate}
                sx={{ backgroundColor: 'red' }}
                variant="gradient"
                loadingPosition="start"
                onClick={fetchBTLTransactionHistory}
              >
                Filter
              </LoadingButton>
              <Box
                sx={{
                  height: '2.5rem',
                  width: '2.5rem',
                  borderRadius: '0.2rem',
                  backgroundColor: '#fff',
                  color: '#000',
                  boxShadow: 4,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer',
                  marginX: 1
                }}
                onClick={async () => {
                  await downloadBtlTransactionsReport('pdf');
                }}
              >
                <Box
                  component="img"
                  sx={{
                    p: 1
                  }}
                  src={PDFDownloadIcon}
                />
              </Box>
              <Box
                sx={{
                  height: '2.5rem',
                  width: '2.5rem',
                  borderRadius: '0.2rem',
                  backgroundColor: '#fff',
                  color: '#000',
                  boxShadow: 4,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer'
                }}
                onClick={async () => {
                  await downloadBtlTransactionsReport('csv');
                }}
              >
                <Box
                  component="img"
                  sx={{
                    p: 1
                  }}
                  src={CSVDownloadIcon}
                />
              </Box>
            </Grid>

            {/* Download Icon */}
          </Grid>
          {/* Token Details */}
          <Box sx={{ my: 3 }}>
            <Typography>
              <strong>Max Token Supply</strong> : {currentToken?.total_supply}
            </Typography>
            <Typography>
              <strong>Total Supply (Sold)</strong> : {totalTokenSoldCount}
            </Typography>
            {data.length > 0 && (
              <Typography>
                <strong>Realized P&L</strong> : ${totalRealizedPnl}
              </Typography>
            )}
          </Box>
          {/* Table */}
          <PaginationTable
            handlePageUpdate={setPage}
            tableTitle=""
            columns={headCells}
            refreshFunction={fetchBTLTransactionHistory}
            data={data}
            setData={setData}
            updateMyData={updateMyData}
            skipPageReset={skipPageReset}
            count={totalCount}
          />
        </CardContent>
      </Card>
    </Container>
  );
};

export default BTLTransactionReport;
