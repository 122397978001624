import constate from 'constate';
import { useState, useEffect } from 'react';
import { useHttpApi } from 'state';
import { toChecksumAddress, xdcToEthAddress } from 'helpers/web3';
import { useSnackbar } from 'notistack';

const useSubscriptionState_ = () => {
  const {
    getAllPublishedTokens,
    getSubscriptionByAddress,
    getAllotmentsByXinFinAddress,
    getAllRedeemedAllotments,
    getAllOrganizations,
    getAllSubscriptionsByXinFinAddress,
    getAllPublishedPTTokens,
    getDailyTvl
  } = useHttpApi();

  const [tokens, setTokens] = useState([]);
  const [ptTokens, setPTTokens] = useState([]);
  const [subscription, setSubscription] = useState(null);
  const [toUpdate, setToUpdate] = useState(false);
  const [allotments, setAllotments] = useState([]);
  const [redeemedAllotments, setRedeemedAllotments] = useState([]);
  const [openTransaction, setOpenTransaction] = useState([]);
  const [dailyTvl, setDailyTvl] = useState([]);
  // const [orgLogo, setOrgLogo] = useState('');

  const fetchTokens = async () => {
    const response = await getAllPublishedTokens();
    const ptResponse = await getAllPublishedPTTokens();
    setPTTokens(ptResponse);
    setTokens(response);
  };
  const fetchPTTokens = async () => {
    const response = await getAllPublishedPTTokens();
    setPTTokens(response);
  };

  const fetchRedeemedAllotments = async (xinfinAddr) => {
    const response = await getAllRedeemedAllotments(xinfinAddr);
    setRedeemedAllotments(response);
  };

  // const fetchLogo = async () => {
  //   const response = await getAllOrganizations();
  //   setOrgLogo(response[0].logo);
  // };

  const fetchDailyTvl = async () => {
    const response = await getDailyTvl();
    setDailyTvl(response);
  };

  const _loaddata = async () => {
    // await fetchLogo();
    await fetchTokens();
  };

  // eslint-disable-next-line camelcase
  const getSubscriberDetails = async (address, token_id) => {
    console.log('data', address, token_id);
    // eslint-disable-next-line camelcase
    const xinfin_address = toChecksumAddress(xdcToEthAddress(address));

    const response = await getSubscriptionByAddress(xinfin_address, token_id);
    console.log('response', response);
    if (response.investor_id) {
      setSubscription(response);
      setToUpdate(true);
      return true;
    } else {
      setSubscription(null);
      setToUpdate(false);
      return false;
    }
  };
  const fetchAllotmentsByXinfinAddress = async (xinfinAddress) => {
    const res = await getAllotmentsByXinFinAddress(xinfinAddress);
    if (res) {
      setAllotments(res);
    }
  };

  const fetchOpenTransactionData = async (account) => {
    const result = await getAllSubscriptionsByXinFinAddress(toChecksumAddress(account));
    setOpenTransaction(result);
  };

  useEffect(() => {
    _loaddata();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    tokens,
    fetchTokens,
    ptTokens,
    getSubscriberDetails,
    subscription,
    toUpdate,
    fetchAllotmentsByXinfinAddress,
    allotments,
    fetchRedeemedAllotments,
    redeemedAllotments,
    openTransaction,
    setOpenTransaction,
    fetchOpenTransactionData,
    fetchPTTokens,
    dailyTvl,
    fetchDailyTvl
  };
};

export const [SubscriptionStateProvider, useSubscriptionState] = constate(useSubscriptionState_);
