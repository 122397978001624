import {
  Container,
  Card,
  Grid,
  Box,
  CardContent,
  Typography,
  TextField,
  Divider,
  Button,
  IconButton,
  InputLabel,
  FormLabel,
  ListItem,
  Select,
  FormControl,
  FormControlLabel,
  Switch
} from '@mui/material';
import Page from 'components/Page';
import React, { useEffect, useState } from 'react';
import Breadcrumbs from 'components/Breadcrumbs';
import PropTypes from 'prop-types';
import { useAuthStateShared, useBusinessEntityState, useWeb3, useHttpApi } from 'state';
import { useNavigate } from 'react-router';
import { useSnackbar } from 'notistack';
import { ethToXdcAddress, stringToBytes32 } from 'helpers/web3';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import LoadingActionButton from 'helpers/LoadingActionButton';
import { format, isBefore } from 'date-fns';

const ViewToken = () => {
  const { currentToken: token, currentTokenRuleDetails: rules } = useBusinessEntityState();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { web3, getTokenDocument } = useWeb3();
  const { get0xSha256Hash } = useHttpApi();
  const [fetchedHash, setFetchedHash] = useState('');
  const [contractDocumentHash, setContractDocumentHash] = useState('Fetching...');
  const [enterHashManually, setEnterHashManually] = useState(false);
  const today = new Date();
  // get today as per issue date format
  const todayDate = format(today, 'yyyy-MM-dd');

  const TextFieldInput = ({ header, subheader, readOnlyValue = true, multiline }) => {
    return (
      <>
        <FormLabel sx={{ color: '#000', fontFamily: 'BeVietnamPro', mb: 1 }}>{header}</FormLabel>
        <TextField
          id="read-only-input"
          className="light-bg"
          defaultValue={subheader}
          fullWidth
          sx={{
            '& .MuiInputBase-input': {
              fontFamily: 'BeVietnamPro !important',
              fontWeight: 'bold' // Apply to the input text
            },
            // fontWeight: '900',
            backgroundColor: '#f5f8fa',
            mt: 2,
            mb: 2
          }}
          multiline={multiline}
          InputProps={{
            readOnly: readOnlyValue
          }}
          variant="outlined"
        />
      </>
    );
  };
  TextFieldInput.propTypes = {
    header: PropTypes.string,
    subheader: PropTypes.string
  };

  return (
    <>
      <Page title="View Token">
        <Container sx={{ my: 2 }}>
          <Box sx={{ my: 2 }}>
            {/* <Breadcrumbs pageHead="" /> */}
          </Box>
          <Container sx={{}}>
            <Card
              sx={{
                paddingX: 2,
                paddingY: 1
              }}
            >
              {/* <Divider
                sx={{
                  width: '100%',
                  backgroundColor: '#f5f8fa',
                  border: '2px solid #f5f8fa'
                }}
              /> */}
              <CardContent sx={{ mb: 2 }}>
                <Card sx={{ mt: 2, border: '1px solid #BBBBBB !important', borderRadius: 0 }}>
                  <Typography
                    variant="h4"
                    sx={{
                      fontSize: '1.125rem',
                      fontWeight: 'bold',
                      mt: 4,
                      mb: 1,
                      pl: 8
                    }}
                  >
                    Token Details
                  </Typography>
                  <CardContent>
                    <Typography variant="subtitle1" sx={{ pl: 6, fontWeight: '600' }}>
                      Token Header
                    </Typography>
                    <Grid container spacing={2} sx={{ width: '100%', mt: 1, pr: 6, pl: 6 }}>
                      <Grid item lg={4} md={6} xs={12} sx={{ pr: 1 }}>
                        <TextFieldInput
                          header="Issuer name"
                          subheader={token.issuer_id.issuer_name}
                        />
                      </Grid>
                      <Grid item lg={4} md={6} xs={12} sx={{ pr: 1 }}>
                        <TextFieldInput header="Issue Currency" subheader="USD" />
                      </Grid>
                      <Grid item lg={4} md={6} xs={12} sx={{ pr: 1 }}>
                        <TextFieldInput header="Token Name" subheader={token.token_name} />
                      </Grid>
                      <Grid item lg={4} md={6} xs={12} sx={{ pr: 1 }}>
                        <TextFieldInput header="Token Symbol" subheader={token.token_symbol} />
                      </Grid>
                      <Grid item lg={4} md={6} xs={12} sx={{ pr: 1 }}>
                        <TextFieldInput header="Token Max Supply" subheader={token.total_supply} />
                      </Grid>
                      <Grid item lg={4} md={6} xs={12} sx={{ pr: 1 }}>
                        <TextFieldInput header="Token Value(USD)" subheader={token.token_value} />
                      </Grid>
                      <Grid item lg={4} md={6} xs={12} sx={{ pr: 1 }}>
                        <TextFieldInput
                          header="Max Issue Size(USD)"
                          subheader={token.max_issue_size}
                        />
                      </Grid>
                      <Grid item lg={4} md={6} xs={12} sx={{ pr: 1 }}>
                        <TextFieldInput
                          header="Token Category"
                          subheader={token.issue_category_id.issue_category_name}
                        />
                      </Grid>
                      <Grid item lg={4} md={6} xs={12} sx={{ pr: 1 }}>
                        <TextFieldInput
                          header="Issue Type"
                          subheader={token.issue_type_id.issue_type_name}
                        />
                      </Grid>
                      <Grid item lg={4} md={6} xs={12} sx={{ pr: 1 }}>
                        <TextFieldInput header="Fund Manager" subheader={token.fund_manager} />
                      </Grid>
                    </Grid>
                    <Divider sx={{ mt: 4 }} />
                    <Divider sx={{ mb: 4, width: '100%' }} />
                    <Typography variant="subtitle1" sx={{ pl: '50px', fontWeight: '600' }}>
                      Token Economics Terms
                    </Typography>
                    <Grid container spacing={2} sx={{ width: '100%', mt: 1, pr: 6, pl: 6 }}>
                      <Grid item lg={4} md={6} xs={12} sx={{ pr: 1 }}>
                        <TextFieldInput
                          header="Issue Start Date"
                          subheader={token.token_start_date}
                        />
                      </Grid>
                      <Grid item lg={4} md={6} xs={12} sx={{ pr: 1 }}>
                        <TextFieldInput
                          header="Min. Subs. Qty (Lot size)"
                          subheader={token.minimum_subscription}
                        />
                      </Grid>
                      <Grid item lg={4} md={6} xs={12} sx={{ pr: 1 }}>
                        <TextFieldInput header="Blockchain" subheader={token.blockchain} />
                      </Grid>
                      <Grid item lg={4} md={6} xs={12} sx={{ pr: 1 }}>
                        <TextFieldInput header="Token Price(USD)" subheader={token.token_nav} />
                      </Grid>
                      <Grid item lg={4} md={6} xs={12} sx={{ pr: 1 }}>
                        <TextFieldInput header="Platform Fees(%)" subheader={token.platform_fees} />
                      </Grid>

                      <Grid item lg={4} md={6} xs={12} sx={{ pr: 1 }}>
                        <TextFieldInput
                          header="Mode of Payment"
                          subheader={token.mode_of_payment}
                        />
                      </Grid>

                      <Grid item lg={4} md={6} xs={12} sx={{ pr: 1 }}>
                        <TextFieldInput
                          header="Setllement Cycle (T + Days)"
                          subheader={token.settlement_cycle}
                        />
                      </Grid>
                      <Grid item lg={8} md={6} xs={12} sx={{ pr: 1 }}>
                        <TextFieldInput
                          header="Use Of Token sale Proceeds"
                          subheader={token.use_of_proceeds}
                          multiline
                        />
                      </Grid>

                      <Grid item lg={4} md={6} xs={12} sx={{ pr: 1 }} />
                      <Grid item lg={4} md={6} xs={12} sx={{ pr: 1 }} />
                    </Grid>
                    <Grid
                      container
                      spacing={2}
                      sx={{ width: '100%', mt: 1, pr: 6, pl: 6, justifyContent: 'center' }}
                    >
                      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                        <Button
                          variant="contained"
                          sx={{
                            width: {},
                            marginX: 'auto',
                            // display: 'flex',
                            bgcolor: '#007DFF',
                            paddingY: 1,
                            borderRadius: '3px'
                          }}
                          onClick={() => {
                            window.open(token.contract_doc_hash, '_blank');
                          }}
                        >
                          {/* <Grid
                            item
                            lg={12}
                            md={6}
                            xs={12}
                            sx={{
                              display: 'flex',
                              placeSelf: 'center center'
                            }}
                          > */}
                          <Typography sx={{ fontFamily: 'BeVietnamPro' }}>
                            Token Terms Document
                          </Typography>
                          <OpenInNewIcon sx={{ ml: 1 }} />
                          {/* </Grid> */}
                        </Button>
                      </Box>
                    </Grid>
                  </CardContent>
                </Card>
                <Grid
                  item
                  lg={12}
                  md={12}
                  xs={12}
                  sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}
                >
                  <Button
                    sx={{
                      fontFamily: 'BeVietnamPro',
                      px: 3,
                      mr: 1,
                      width: '7.75rem',
                      height: '2.5rem',
                      bgcolor: '#000',

                      '&:hover': {
                        bgColor: '#007DFF'
                      }
                    }}
                    variant="contained"
                    // color="secondary"
                    onClick={() => {
                      navigate('/subscription');
                    }}
                  >
                    Back
                  </Button>
                </Grid>
              </CardContent>
            </Card>
          </Container>
        </Container>
      </Page>
    </>
  );
};

export default ViewToken;
