import { Typography, IconButton, Box, TextField, Button, FormLabel } from '@mui/material';
import Modal from '@mui/material/Modal';
import { useState, useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useWeb3State } from 'state/useWeb3State';
import { useAppState, useBusinessEntityState } from 'state';
import { useSnackbar } from 'notistack';
import LoadingActionButton from 'helpers/LoadingActionButton';

const BTLMintModal = ({ open, setOpenModal, totalSupply, tokenBalance }) => {
  const [mintQuantity, setMintQuantity] = useState('');
  const { mint, getTotalSupply } = useWeb3State();
  const { currentToken } = useBusinessEntityState();
  const { throwErrorMessage } = useAppState();
  const { enqueueSnackbar } = useSnackbar();
  console.log(mintQuantity);
  const handleClose = () => {
    setOpenModal(false);
    setMintQuantity('');
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableEscapeKeyDown
      disableEnforceFocus
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          width: 'auto',
          p: 4,
          boxShadow: 24,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          borderRadius: '10px'
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'flex-start', mt: 2 }}>
          <Button sx={{ width: '15rem', mx: 2 }} variant="contained">
            Max Supply
          </Button>
          <TextField
            fullWidth
            size="small"
            autoComplete="off"
            type="text"
            placeholder="Enter quantity to mint"
            id="mintQuantity"
            inputProps={{ maxLength: 50, width: 'auto', readOnly: true }}
            value={currentToken.total_supply}
          />
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'flex-start', mt: 2 }}>
          <Button sx={{ width: '15rem', mx: 2 }} variant="contained">
            Total Supply
          </Button>
          <TextField
            fullWidth
            size="small"
            autoComplete="off"
            type="text"
            placeholder="Enter quantity to mint"
            id="mintQuantity"
            inputProps={{ maxLength: 50, width: 'auto', readOnly: true }}
            value={totalSupply}
          />
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'flex-start', mt: 2 }}>
          <Button sx={{ width: '15rem', mx: 2 }} variant="contained">
            BTL Balance
          </Button>
          <TextField
            fullWidth
            size="small"
            autoComplete="off"
            type="text"
            placeholder="Enter quantity to mint"
            id="mintQuantity"
            inputProps={{ maxLength: 50, width: 'auto', readOnly: true }}
            value={tokenBalance}
          />
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'flex-start', mt: 2 }}>
          <Button sx={{ width: '15rem', mx: 2 }} variant="contained">
            Tokens
          </Button>
          <TextField
            fullWidth
            size="small"
            autoComplete="off"
            type="number"
            placeholder="Enter quantity to mint"
            id="mintQuantity"
            inputProps={{ maxLength: 50, width: 'auto' }}
            onChange={(e) => {
              setMintQuantity(e.target.value);
            }}
          />
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'flex-start', mt: 2 }}>
          <LoadingActionButton
            disabled={!mintQuantity}
            loadingPosition="start"
            sx={{
              width: '7.5rem',
              height: '2.5rem',
              mt: 3,
              mx: 1
            }}
            variant="contained"
            onClick={async () => {
              try {
                if (mintQuantity && mintQuantity > 0) {
                  await mint(currentToken.deployment_address, mintQuantity);
                  enqueueSnackbar('Mint Request in progress', {
                    variant: 'success'
                  });
                  setMintQuantity('');
                  handleClose();
                } else {
                  enqueueSnackbar('Enter a valid token quantity', { variant: 'error' });
                  return;
                }
              } catch (e) {
                throwErrorMessage(e);
              }
            }}
          >
            Mint
          </LoadingActionButton>
          <Button
            sx={{ width: '7.5rem', height: '2.5rem', mt: 3, mx: 1, backgroundColor: 'black' }}
            variant="contained"
            onClick={handleClose}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default BTLMintModal;
