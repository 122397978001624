import {
  Typography,
  Stack,
  TextField,
  Box,
  Grid,
  FormLabel,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Alert,
  Link,
  AlertTitle,
  Card
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { useAuthState, useSubscriptionState, useAppState, useCoreTableState } from 'state';
import XinfinLogin from 'components/XinfinLogin';
import * as Yup from 'yup';

const RequestForgotPassword = () => {
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const { forgotPassword } = useAuthState();
  const { orgLogo } = useSubscriptionState();
  const { throwErrorMessage } = useAppState();
  const { fetchOrganizations, orgnizations } = useCoreTableState();

  useEffect(() => {
    fetchOrganizations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string().email('Invalid Email Format').required('Email is required')
  });
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const formik = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: ForgotPasswordSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        setError(null);
        await forgotPassword(values);
        enqueueSnackbar('Email Sent Successfully', { variant: 'success' });
        handleOpen();
      } catch (e) {
        const errorMessage = await throwErrorMessage(e);
        setError(errorMessage);
      }
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;
  return (
    <Card
      className="dark-theme"
      sx={{
        mx: 'auto',
        backgroundColor: '#0D0D5E',
        borderRadius: '8px',
        width: 483.38,
        height: 'auto',
        padding: 3
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          mb: '2rem',
          marginTop: 2
        }}
      >
        {orgnizations[0] && (
          <Box component="img" src={orgnizations[0].logo} sx={{ height: '6rem', width: 'auto' }} />
        )}
      </Box>
      <XinfinLogin />
      <Box
        sx={{
          paddingX: 4,
          paddingY: 2
        }}
      >
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Typography
              variant="h4"
              sx={{
                mb: 2,
                fontSize: '2rem',
                color: '#fff',
                textAlign: 'center',
                fontWeight: 800
              }}
            >
              Reset Password of your Account
            </Typography>
            {error && (
              <Box mb={4}>
                <Alert severity="error">{error}</Alert>
              </Box>
            )}
            <Typography
              sx={{
                mb: 4,
                fontSize: '1rem',
                color: '#FFF',
                fontFamily: 'Manrope',
                fontWeight: 400
              }}
            >
              Enter Email associated with your account and we'll send you an email with instructions
              to reset your password.
            </Typography>
            <Stack spacing={3}>
              <FormLabel>Email Address</FormLabel>
              <TextField
                fullWidth
                autoComplete="username"
                type="email"
                onChange={(e) => {
                  formik.setFieldValue('email', e.target.value);
                  if (error) {
                    setError(null);
                  }
                }}
                // {...getFieldProps('email')}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
                placeholder="Enter Email address"
              />
            </Stack>
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="gradient"
              loadingPosition="start"
              loading={isSubmitting}
              sx={{
                mt: 4,
                borderRadius: '60px',
                fontFamily: 'Hind',
                fontSize: '18px'
              }}
            >
              Submit
            </LoadingButton>
          </Form>
        </FormikProvider>
      </Box>

      <Box
        sx={{
          mx: 'auto',
          // p: 1,
          textAlign: 'center',
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Button
          sx={{
            fontFamily: 'Cy Semibold',
            fontWeight: 600,
            fontSize: '15px',
            color: '#10C5FF',
            mb: 2
          }}
          onClick={() => {
            navigate('/login');
          }}
        >
          Login Page
        </Button>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          sx={{ textAlign: 'center' }}
        >
          <Alert severity="success">
            <AlertTitle sx={{ fontWeight: 600, fontSize: '1.2rem' }}>
              Email Sent Successfully
            </AlertTitle>
          </Alert>
          <DialogContent sx={{ fontWeight: 500, fontSize: '1rem' }}>
            Check your mail, we have sent a password recover link to your email.
          </DialogContent>
          <DialogContent>
            <Button
              variant="gradient"
              color="secondary"
              sx={{ textAlign: 'center', width: '10rem' }}
              onClick={() => {
                setOpen(false);
                navigate('/login');
              }}
            >
              Login Page
            </Button>
          </DialogContent>
          <DialogContent sx={{ fontSize: '0.8rem', color: '#8e8ea7', mt: 2 }}>
            Did not receive the email? Check your spam folder or
            <Link
              sx={{ fontSize: '0.82rem', cursor: 'pointer', ml: '0.1875rem' }}
              onClick={handleClose}
              underline="hover"
            >
              entered email address is correct.
            </Link>
          </DialogContent>
        </Dialog>
      </Box>
    </Card>
  );
};

export default RequestForgotPassword;
