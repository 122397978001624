import { Box, Button, Card, CardContent, Container, Typography } from '@mui/material';
import RecentUpcomingEvents from 'components/bettingEvents/RecentUpcomingBettingEvent';
import Breadcrumbs from 'components/Breadcrumbs';
import TokenTable from 'components/issuerDashboard/TokenTable';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useBusinessEntityState } from 'state';

const BettingEvents = () => {
  const { currentToken: token } = useBusinessEntityState();

  const navigate = useNavigate();

  return (
    <Container>
      <Breadcrumbs aria-label="breadcrumb" pageHead="Betting Events" />

      {/* Token Table */}
      <TokenTable currToken={token} />

      <Box sx={{ my: 4 }}>
        <Typography
          variant="body2"
          color="initial"
          sx={{
            fontSize: '1.125rem',
            fontWeight: 'bold'
          }}
        >
          Upload Upcoming Event Details and Recent Results
        </Typography>
      </Box>
      <RecentUpcomingEvents />
    </Container>
  );
};

export default BettingEvents;
