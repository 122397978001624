import { Magic } from 'magic-sdk';
import { useWeb3 } from 'state';
import { useRef, useState, useEffect } from 'react';
import {
  Container,
  Card,
  Grid,
  Box,
  CardContent,
  Typography,
  TextField,
  FormLabel,
  Button,
  Alert,
  AlertTitle,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  IconButton,
  InputAdornment
} from '@mui/material';
import CircularProgress from '@material-ui/core/CircularProgress';
import CloseIcon from '@mui/icons-material/Close';
import Modal from '@mui/material/Modal';
import OtpIcon from '../../../assets/images/OtpIcon.svg';
import { useSnackbar } from 'notistack';

const MagicLinkModal = ({ openModal, userEmail, setOpenOtpModal }) => {
  const {
    verifyOtp,
    otpVerificationOngoing,
    setOtpVerificationOngoing,
    connectedAccount,
    otpVerificationComplete,
    otpExpiryTimestamp,
    emailOtpHandler,
    otpExpired
  } = useWeb3();

  const { enqueueSnackbar } = useSnackbar();

  const [tryCount, setTryCount] = useState(0);
  const otpModalCloseRef = useRef(null);
  const [otp, setOtp] = useState(['', '', '', '', '', '']);
  const inputRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null)
  ];

  const incrementTryCount = () => {
    setTryCount((prev) => prev + 1);
  };

  const handleInputChange = async (index, value, event) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    // Move to the next input field if value is not empty
    if (value !== '' && index < 5 && inputRefs[index + 1]?.current) {
      if (inputRefs[index + 1].current) {
        inputRefs[index + 1]?.current?.focus();
      }
    }

    // Move to the previous input field if backspace is pressed and the current input is empty
    if (event.key === 'Backspace' && value === '' && index > 0 && inputRefs[index - 1]?.current) {
      console.log('backspace');
      if (inputRefs[index - 1].current) {
        inputRefs[index - 1]?.current?.focus();
      }
    }

    // Call submit otp api if all the otp fields are filled

    if (newOtp.join('').length >= 6) {
      setOtpVerificationOngoing(true);
      console.log('OTP IS', newOtp.join(''));
      if (tryCount <= 2) {
        incrementTryCount();
        await verifyOtp(newOtp.join(''), otpModalCloseRef, tryCount);
        setOtp(['', '', '', '', '', '']);
      }
    }
  };

  const handlePaste = async (event) => {
    const pastedData = event.clipboardData.getData('text/plain');
    const pastedChars = pastedData.split('');

    // Update OTP values
    const newOtp = [...otp];
    for (let i = 0; i < pastedChars.length && i < 6; i += 1) {
      newOtp[i] = pastedChars[i];
    }
    setOtp(newOtp);

    // Move focus to the next input
    if (pastedChars.length < 6 && inputRefs[pastedChars.length]?.current) {
      if (inputRefs[pastedChars.length].current) {
        inputRefs[pastedChars.length]?.current?.focus();
      }
    }
    // Call submit otp api if all the otp fields are filled
    if (pastedChars.length >= 6) {
      setOtpVerificationOngoing(true);
      console.log('OTP IS', newOtp.join(''));
      if (tryCount <= 2) {
        incrementTryCount();
        await verifyOtp(newOtp.join(''), otpModalCloseRef, tryCount);
        setOtp(['', '', '', '', '', '']);
      }
    }
  };

  const [timeRemaining, setTimeRemaining] = useState(otpExpiryTimestamp);
  const handleCancelLogin = () => {
    try {
      emailOtpHandler?.emit('cancel');
      otpModalCloseRef?.current?.click();
    } catch (e) {
      console.log('User cancelled the login');
    }
  };
  useEffect(() => {
    if (otpExpiryTimestamp) {
      // Update the remaining time every second
      const interval = setInterval(() => {
        const now = Date.now();
        const diff = otpExpiryTimestamp - now;

        if (diff <= 0) {
          clearInterval(interval);
          setTimeRemaining('');
        } else {
          const minutes = Math.floor(diff / 60000);
          const seconds = Math.floor((diff % 60000) / 1000);
          setTimeRemaining(`${minutes}:${seconds.toString().padStart(2, '0')}`); // Format as MM:SS
        }
      }, 1000);

      return () => clearInterval(interval); // Cleanup on unmount
    }
  }, [otpExpiryTimestamp]);

  return (
    <Modal
      className="dark-theme"
      open={openModal}
      // onClose={()=>{setOpenOtpModal(false)}}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          boxShadow: 24,
          p: 4,
          backgroundColor: '#0D0D5E',
          borderRadius: '8px'
        }}
      >
        <Box display="flex" justifyContent="flex-end" sx={{ display: 'none' }}>
          <IconButton
            aria-label="close"
            ref={otpModalCloseRef}
            onClick={() => {
              setTimeRemaining('');
              setTryCount(0);
              setOpenOtpModal(false);
              setOtp(['', '', '', '', '', '']);
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Box textAlign="center" mb={2} sx={{ paddingY: 4 }}>
          <img
            src={OtpIcon}
            alt="logo"
            style={{
              width: '100px',
              height: '100px',
              margin: 'auto'
            }}
          />
          {!otpExpired && !otpVerificationOngoing && (
            <>
              <Typography variant="h4" mt={2} sx={{ color: '#FFF' }}>
                Enter OTP
                <br />
              </Typography>
              <Typography variant="h6" mt={2} sx={{ color: '#FFF' }}>
                Please enter the code sent to <br />
                <strong>{userEmail}</strong>
              </Typography>
            </>
          )}

          {otpExpired && !otpVerificationOngoing && (
            <>
              <Typography variant="h4" mt={2} sx={{ color: '#FFF' }}>
                Your OTP has expired
                <br />
              </Typography>
              <Box textAlign="center" mt={2} onClick={handleCancelLogin}>
                <Button variant="contained" sx={{ color: '#FFF' }}>
                  Try login/sign-up again
                </Button>
              </Box>
            </>
          )}

          {otpVerificationOngoing && (
            <>
              <Typography variant="h4" mt={2} sx={{ color: '#FFF' }}>
                Verifying OTP
                <br />
              </Typography>
              <Typography variant="h6" mt={2} sx={{ color: '#FFF' }}>
                Please wait
              </Typography>
            </>
          )}
        </Box>

        {otpVerificationOngoing ? (
          <Box display="flex" justifyContent="center" my={2}>
            <CircularProgress
              sx={{
                disableShrink: true
              }}
            />
          </Box>
        ) : (
          <>
            {!otpExpired && (
              <>
                <Box display="flex" justifyContent="center" mt={2}>
                  {otp.map((value, index) => (
                    <TextField
                      key={index}
                      autoFocus={(index === 0 && !value) || (index === 5 && value)}
                      inputProps={{
                        style: { textAlign: 'center' },
                        maxLength: 1
                      }}
                      value={value}
                      onChange={(e) => {
                        try {
                          handleInputChange(index, e.target.value, e.nativeEvent);
                        } catch (e) {
                          console.log(e);
                        }
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Backspace' && !value) {
                          handleInputChange(index, '', e);
                        }
                      }}
                      onPaste={(e) => handlePaste(e)}
                      inputRef={inputRefs[index]}
                      style={{ margin: '0 8px', width: '40px' }}
                    />
                  ))}
                </Box>
                <Box mt={2}>
                  <Typography variant="body2" sx={{ color: '#FFF', textAlign: 'center' }}>
                    Tries remaining: {3 - tryCount}
                  </Typography>
                </Box>
                <Box mt={2}>
                  <Typography variant="body2" sx={{ color: '#FFF', textAlign: 'center' }}>
                    {otpExpired ? 'OTP Expired' : `OTP expires in : ${timeRemaining ?? ''}`}
                  </Typography>
                </Box>
              </>
            )}
            <Box mt={2} textAlign="center">
              <Typography variant="body2" sx={{ color: '#FFF' }}>
                Secured By <span style={{ color: '#10C5FF' }}>Magic</span>
              </Typography>
            </Box>
          </>
        )}
      </Box>
    </Modal>
  );
};

export default MagicLinkModal;
