import React from 'react';
import {
  Box,
  Table,
  TableHead,
  TableContainer,
  TableRow,
  TableCell,
  TableBody,
  Typography
} from '@mui/material';
import { makeStyles } from '@material-ui/core';
import AddressFieldTools from 'components/AddressFieldTools';

const useStyles = makeStyles((theme) => ({
  tablecontainer: {},
  tableRow: {
    '& .MuiTableCell-root': {
      fontFamily: 'BeVietnamPro, sans-serif', // Custom font
      fontSize: '14px', // Font size
      color: '#333' // Text color
    }
  }
}));

const InvestorTableSummary = ({ data, page }) => {
  //   console.log('summary data - - - ', data);

  let fifthColumn;

  if (page === 'ALLOTMENT') {
    if (data?.total_quantity && data?.weighted_price) {
      fifthColumn = (data.total_quantity * data.weighted_price).toFixed(2);
    } else {
      fifthColumn = '-';
    }
  } else {
    fifthColumn = (data?.avg_buy_price).toFixed(2) ?? '-';
  }

  const classes = useStyles();
  return (
    <Box
      sx={{
        border: '1px solid #BBBBBB',
        borderRadius: '0px',
        boxShadow: 1
      }}
    >
      <TableContainer sx={{ width: '100%' }}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow
              // className={classes.tableRow}
              sx={{ fontWeight: '500' }}
            >
              <TableCell sx={{ fontWeight: 'bold', px: 6 }}>Token Symbol</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Token Address</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>
                {page === 'ALLOTMENT' ? 'Token Holding' : 'Token Redeemed'}
              </TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>
                {page === 'ALLOTMENT' ? 'Avg. buy Price' : 'Avg. sell Price'}
              </TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>
                {page === 'ALLOTMENT' ? 'Value at Cost' : 'Avg. buy Price'}
              </TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Current Price</TableCell>
              {page !== 'ALLOTMENT' && (
                <TableCell sx={{ fontWeight: 'bold' }}>Realized P&L</TableCell>
              )}
            </TableRow>
          </TableHead>

          <TableBody>
            {data && (
              <TableRow>
                {/* <Typography variant="body1" sx={{ color: 'text.primary',fontFamily:'BeVietnamPro', maxWidth:'150px' }} noWrap> */}
                <TableCell sx={{ px: 6 }}>
                  {page === 'ALLOTMENT'
                    ? data?.token_subscription_id?.token_id?.token_name
                    : data?.token?.token_name}
                </TableCell>
                {/* </Typography> */}
                <TableCell>
                  <Typography
                    variant="body1"
                    sx={{ color: 'text.primary', fontFamily: 'BeVietnamPro', maxWidth: '200px' }}
                    noWrap
                  >
                    <AddressFieldTools
                      address={
                        page === 'ALLOTMENT'
                          ? data?.token_subscription_id?.token_id?.deployment_address
                          : data?.token?.deployment_address
                      }
                      decimals={0}
                      showCopyButton
                      showInBlockExplorer
                    />
                  </Typography>
                </TableCell>
                <TableCell>{data?.total_quantity}</TableCell>
                <TableCell>{data?.weighted_price ? data.weighted_price.toFixed(2) : '-'}</TableCell>
                <TableCell>{fifthColumn}</TableCell>
                <TableCell>
                  {page === 'ALLOTMENT' ? data?.token_nav : data?.token?.token_nav}
                </TableCell>
                {page !== 'ALLOTMENT' && (
                  <TableCell>
                    {page === 'ALLOTMENT'
                      ? (data?.total_quantity ?? 0) *
                        ((data?.token_nav ?? 0) - (data?.weighted_price ?? 0)).toFixed(2)
                      : (data?.total_quantity ?? 0) *
                        ((data?.weighted_price ?? 0) - (data?.avg_buy_price ?? 0)).toFixed(2)}
                  </TableCell>
                )}
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default InvestorTableSummary;
