import React from 'react';
import {
  Box,
  Table,
  TableHead,
  TableContainer,
  TableRow,
  TableCell,
  TableBody
} from '@mui/material';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  tablecontainer: {
    // [theme.breakpoints.down('sm')]: {
    //   width: 450
    // },
    // [theme.breakpoints.between('md', 'sm')]: {
    //   width: 700
    // },
    // [theme.breakpoints.between('lg', 'md')]: {
    //   width: 1050
    // }
  },
  tableRow: {
    '& .MuiTableCell-root': {
      fontFamily: 'BeVietnamPro, sans-serif', // Custom font
      fontSize: '14px', // Font size
      color: '#333' // Text color
    }
  }
}));
// eslint-disable-next-line no-unused-vars
const InvestorTokenTable = (props) => {
  const classes = useStyles();
  return (
    <Box
      sx={{
        border: '1px solid #BBBBBB',
        borderRadius: '0px'
      }}
    >
      <TableContainer className={classes.tablecontainer}>
        <Table>
          <TableHead>
            <TableRow className={classes.tableRow} sx={{ fontWeight: '500' }}>
              <TableCell sx={{ color: '#8E8EA7', px: 6 }}>Name</TableCell>
              <TableCell sx={{ color: '#8E8EA7' }}>Symbol</TableCell>
              {/* <TableCell sx={{ color: '#8E8EA7' }}>Issue Type.....</TableCell> */}
              <TableCell sx={{ color: '#8E8EA7' }}>Currency</TableCell>
              <TableCell sx={{ color: '#8E8EA7' }}>Issue Size</TableCell>
              <TableCell sx={{ color: '#8E8EA7' }}>Price</TableCell>
              <TableCell sx={{ color: '#8E8EA7' }}>Min. Subs. (Token)</TableCell>
              <TableCell sx={{ color: '#8E8EA7' }}>Max Supply</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props && (
              <TableRow className={classes.tableRow}>
                <TableCell sx={{ fontWeight: 'bold', px: 6 }}>{props?.token_name}</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>{props?.token_symbol}</TableCell>
                {/* <TableCell sx={{ fontWeight: 'bold' }}>
                  {props?.issue_type_id?.issue_type_name}
                </TableCell> */}
                <TableCell sx={{ fontWeight: 'bold' }}>
                  {props?.token_currency?.currency_name}
                </TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>{props?.max_issue_size}</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>{props?.token_nav}</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>{props?.minimum_subscription}</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>{props?.total_supply}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default InvestorTokenTable;
