import constate from 'constate';
import { getErrorMessage } from 'helpers/error';
import { useSnackbar } from 'notistack';
import { useHttpApi } from './useHttpApi';
import { io } from 'socket.io-client';
import { useEffect, useState } from 'react';
import { useWeb3State } from './useWeb3State';
import { NODE_API_URL } from 'config';
import { useBusinessEntityState } from './useBusinessEntityState';
import SmartContract from '@yodaplus/dapps-lib/contracts/apothem.json';
import Web3 from 'web3';

const useAppState_ = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { currentToken, fetchCurrentToken, fetchTokens, setCurrentTokenById } =
    useBusinessEntityState();
  const {
    getErrorString,
    getLoggedInUserNotifications,
    getLoggedInUserAllReadNotifications,
    getNotificationsByAddress,
    getAllReadNotificationByAddress,
    getTokenDetailsById,
    getTokenById
  } = useHttpApi();
  const { account, web3 } = useWeb3State();
  const [notifications, setNotifications] = useState([]);
  const [readNotification, setReadNotifications] = useState([]);
  const [tokenNotifications, setTokenNotifications] = useState([]);
  const [readTokenNotifications, setReadTokenNotifications] = useState([]);
  const [otpVerificationOngoing, setOtpVerificationOngoing] = useState(false);
  const [tokenCreated, setTokenCreated] = useState(false);

  const handleSocketNotificationAction = async (data) => {
    const token_ = JSON.parse(localStorage.getItem('currToken'));
    if (
      currentToken &&
      (data.notification.includes('has been paused') ||
        data.notification.includes('has been unpaused') ||
        data.notification.includes('Token Price') ||
        data.notification.includes('Token Max Supply') ||
        data.notification.includes('Token Status was updated'))
    ) {
      console.log('check for token id', token_.token_id);
      const res = await getTokenDetailsById(token_.token_id);
      console.log('ni socket', res);
      fetchCurrentToken(res);
    }
    if (
      (data.notification.includes('Token Price') ||
        data.notification.includes('Token Max Supply')) &&
      currentToken
    ) {
      // if token nav updates, while on dashboard, update dashboard tokens as well
      fetchTokens();
      const res = await getTokenById(token_.token_id);
      setCurrentTokenById(res);
    }
  };

  const fetchUserNotifications = async () => {
    const res = await getLoggedInUserNotifications();
    setNotifications(res);
  };
  const fetchAllReadNotifications = async () => {
    const res = await getAllReadNotificationByAddress(account);
    setReadNotifications(res);
  };
  const fetchNotificationsByAddress = async () => {
    const res = await getNotificationsByAddress(account);
    setNotifications(res);
  };
  useEffect(async () => {
    if (currentToken && currentToken.deployment_address) {
      const res = await getNotificationsByAddress(currentToken.deployment_address);
      setTokenNotifications(res);
    } else {
      setTokenNotifications('');
    }
  }, [currentToken, notifications]);
  useEffect(async () => {
    if (currentToken && currentToken.deployment_address) {
      const res = await getAllReadNotificationByAddress(currentToken.deployment_address);
      setReadTokenNotifications(res);
    } else {
      setReadTokenNotifications('');
    }
  }, [currentToken, readNotification]);
  useEffect(() => {
    if (account) {
      const socket = io(NODE_API_URL);
      // disconnect all the previous rooms'
      socket.emit('join', account);
      // socket.emit('switch room', { leave: localStorage.getItem('roomName'), account });
      localStorage.setItem('roomName', account);
      socket.on('connect', () => console.log(socket.id));
      socket.on('connect_error', () => {
        setTimeout(() => socket.connect(), 5000);
      });
      socket.on('send', (data) => {
        handleSocketNotificationAction(data);
        if (data.notification.includes('Token Status was updated')) {
          console.log(data.notification);
        } else {
          enqueueSnackbar(data.notification, {
            preventDuplicate: true
          });
          console.log('Check', data.notification);
          fetchNotificationsByAddress();
        }
      });
      socket.on('disconnect', () => console.log('disconnected'));
    }
    fetchNotificationsByAddress();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);

  // function decodeData(abi, data) {
  //   // Check if web3-eth-abi is available (recommended)
  //   if (typeof web3.eth.abi !== 'undefined') {
  //     return web3.eth.abi.decodeParameters(abi, data);
  //   } else {
  //     // Fallback using individual function (less efficient)
  //     const inputs = abi.filter((element) => element.type !== 'constructor');
  //     const decodedParams = {};
  //     let dataOffset = 0;
  //     for (const input of inputs) {
  //       const decodedValue = web3.utils.hexToNumber(data.slice(dataOffset, dataOffset + 64));
  //       decodedParams[input.name] = decodedValue;
  //       dataOffset += 64; // Assuming basic data types (uint256)
  //     }
  //     return decodedParams;
  //   }
  // }

  function cleanString(str) {
    // // Regular expression to remove non-printable characters
    // // This regex retains only printable ASCII characters (from space to tilde)
    // const printableASCII = /[ -~]/g;

    // // Extract only the printable ASCII characters from the input string
    // const cleanStr = str.match(printableASCII).join('');

    // // Trim leading and trailing white spaces (if any)
    // const trimmedStr = cleanStr.trim();

    // return trimmedStr;

    // Regular expression to retain only alphanumeric characters (letters and numbers)
    const alphanumericPattern = /[a-zA-Z0-9 ]/g;

    // Extract and concatenate only alphanumeric characters
    const alphanumericStr = str.match(alphanumericPattern).join('');

    // Trim any leading or trailing white spaces
    const trimmedStr = alphanumericStr.trim();
    console.log('🚀 ~ cleanString ~ trimmedStr:', trimmedStr);

    return trimmedStr;
  }

  const throwErrorMessage = async (error) => {
    let newMessage = 'Something went wrong! Please try again.';
    try {
      // check if error.message consist of "Internal JSON-RPC error" or "execution reverted"
      if (
        typeof error === 'object' &&
        error.message.includes(
          'Magic RPC Error: [-32603] Error forwarded from node, insufficient funds for gas * price + value, undefined'
        )
      ) {
        newMessage =
          'Insufficient XDC Balance for processing the transaction. Please contact BettorToken Support.';
      } else if (
        typeof error === 'object' &&
        Object.keys(error).length === 0 &&
        (error.message.includes('Internal JSON-RPC error') ||
          error.message.includes('execution reverted'))
      ) {
        const errorParam = error.toString();

        // Extract JSON section from the error string
        const jsonStart = errorParam?.indexOf('{');
        const jsonEnd = errorParam?.lastIndexOf('}') + 1;
        const jsonError = errorParam?.substring(jsonStart, jsonEnd);

        // Parse the extracted JSON part into a JavaScript object
        const errorObject = JSON.parse(jsonError);

        const decodedData = web3.utils.hexToAscii(errorObject.data);
        console.log('🚀 ~ throwErrorMessage ~ decodedData:', decodedData);
        const cleanData = cleanString(decodedData);
        console.log('🚀 ~ throwErrorMessage ~ cleanData:', cleanData);
        const res = await getErrorString(cleanData);
        if (res[0]) {
          newMessage = res[0].status_message;
        }
      } else {
        const _message = getErrorMessage(error);

        newMessage = _message;

        const res = await getErrorString(_message);
        if (res[0]) {
          newMessage = res[0].status_message;
        }
      }
    } catch (error) {
      console.log('💁 ~ throwErrorMessage ~ error:', error);
    }

    // const CustodianContract = new web3.eth.Contract(CustodianContractABI);
    // const decodedData = CustodianContract.(errorObject.data);
    // const decodedData = web3.utils.hexToAscii(
    //   '0x7b40159da00000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000400000000000000000000000000000000000000000000000000000000000000012657363726f7720697320636f6d706c6574650000000000000000000000000000'
    // );
    // console.log('🚀😥😥😥 ~ throwErrorMessage ~ errorObject.data:', errorObject.data);
    // console.log('💁💁💁🚀💁 ~ throwErrorMessage ~ decodedData:', decodedData);
    // console.log('💁💁💁💁 ~ throwErrorMessage ~ decodedData:', typeof decodedData);

    // const decodeData = web3.eth.decodeParameters(CustodianContractABI, errorObject.data);
    // console.log('⚠️⚠️🚀 ~ throwErrorMessage ~ decodeData:', decodeData);

    // // console.log('⚠️⚠️', decodeData(CustodianContractABI, errorObject.data));
    enqueueSnackbar(newMessage, {
      variant: 'error'
    });
    return newMessage;
  };

  return {
    throwErrorMessage,
    fetchUserNotifications,
    notifications,
    readNotification,
    fetchAllReadNotifications,
    fetchNotificationsByAddress,
    tokenNotifications,
    readTokenNotifications,
    otpVerificationOngoing,
    setOtpVerificationOngoing,
    tokenCreated,
    setTokenCreated
  };
};
export const [AppStateProvider, useAppState] = constate(useAppState_);
