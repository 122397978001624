import constate from 'constate';

import { resourcePersistence, userTokenPersistence } from 'persistence';
import { useState } from 'react';

import { useAuthStateShared } from 'state/useAuthStateShared';
import { useHttpApi } from 'state/useHttpApi';
import { useRbacActions } from 'state/useRbac';
import { useMagicState } from './useMagicState';

const useAuthState_ = () => {
  const { userLogin, userLogout, getCurrentUser, userChangePassword, userForgotPassword } =
    useHttpApi();
  const {
    user,
    setUser,
    setLoggedOutState,
    setIsReady,
    setIsAuthorized,
    isReady,
    isAuthorized,
    setOrgLogo,
    setRole,
    setEntity,
    role
  } = useAuthStateShared();
  const { initRbac } = useRbacActions();
  // const {disconnectMagic} = useMagicState()
  const [isInvestor, setIsInvestor] = useState(false);
  const initAuth = async () => {
    if (isReady) {
      return;
    }

    const token = userTokenPersistence.get();

    if (!token) {
      setLoggedOutState();

      setIsReady(true);
      return;
    }

    try {
      const { user, groups, permissions, logo, entity } = await getCurrentUser();
      setOrgLogo(logo);
      initRbac(groups, permissions);
      setUser(user);
      setIsAuthorized(true);
      setRole(user.role);
      setEntity(entity);
      if (user.xinfin_address.role === 'INVESTOR') {
        setIsInvestor(true);
      }
      resourcePersistence.set('entity', entity);
    } catch (e) {
      setLoggedOutState();
    } finally {
      setIsReady(true);
    }
  };

  const login = async (username, password) => {
    const res = await userLogin({
      username,
      password
    });
    if (res?.user?.parent_xinfin_account?.role === 'INVESTOR') {
      setIsInvestor(true);
    }
    return res;
  };

  const processLogin = (userDetails) => {
    const { token, user, groups, permissions, logo, entity } = userDetails;
    setOrgLogo(logo);
    userTokenPersistence.set(token);
    initRbac(groups, permissions);
    setUser(user);
    setIsReady(true);
    setIsAuthorized(true);
    setRole(user.role);
    // console.log('user', user);
    setEntity(entity);
    resourcePersistence.set('entity', entity);
  };

  const logout = async () => {
    // if(role==="INVESTOR"){
    //   await disconnectMagic()
    // }
    await userLogout(user.id);
    setLoggedOutState();
    localStorage.clear();
  };

  const changePassword = async (data) => {
    await userChangePassword(data);
    setLoggedOutState();
  };

  const forgotPassword = async (data) => {
    await userForgotPassword(data);
  };

  return {
    user,
    isReady,
    isAuthorized,
    initAuth,
    login,
    logout,
    changePassword,
    forgotPassword,
    processLogin,
    isInvestor
  };
};

export const [AuthStateProvider, useAuthState] = constate(useAuthState_);
