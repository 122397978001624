import React, { useEffect, useState } from 'react';
import { IconButton, Card, CardContent, Typography, Box } from '@mui/material';
import CreateIcon from '@mui/icons-material/Create';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useBusinessEntityState } from 'state/useBusinessEntityState';
import EnhancedTable from 'components/tables/EnhancedTable';
import IdentityInformationModal from './IdentityInformationModal';
import AccountInformationModal from './AccountInformationModal';
import MailComponent from './MailComponent';
import AddressFieldTools from 'components/AddressFieldTools';
import OfflineCashTransactionModal from 'components/issuerDashboard/OfflineCashTransactionModal';
import { useAuthStateShared } from 'state';
import { onlyIssuer } from 'helpers/rbac';



export default function BTLSubscriptionTable() {
  const {
    whitelistedSubscriptions,
    currentToken: token,
    fetchWhiteListSubscriptions
  } = useBusinessEntityState();
  const [data, setData] = useState(whitelistedSubscriptions);
  const [skipPageReset, setSkipPageReset] = React.useState(false);
  const { role } = useAuthStateShared();


  useEffect(() => {
    fetchWhiteListSubscriptions();
  }, []);
  const headCells = [
    {
      accessor: 'investor_id.investor_name',
      Header: 'Customer Name',
      show: true,
      width:200
    },
    {
      accessor: 'xinfin_account',
      Header: 'Blockchain Address',
      show: true,
      width: 250,
      Cell: ({ row: { original } }) => {
        return (
          <AddressFieldTools
            address={original.investor_id.xinfin_account}
            decimals={0}
            showCopyButton
            showInBlockExplorer
          />
        );
      }
    },
    {
      accessor: 'investor_id.investor_phone_no',
      Header: 'Phone',
      width: 150,
      show: true
    },
    {
      accessor: 'investor_id.investor_country_code.country_code',
      Header: 'Country',
      width: 150,
      show: true
    },

    {
      accessor: 'requested_qty',
      Header: 'Applied Qty',
      show: true,
      width:170
    },
    {
      accessor: 'subscription_payment_method.payment_method',
      Header: 'Payment mode',
      Cell: ({ row: { original } }) => {
        console.log(original);
        return original.subscription_payment_method?.payment_method
          ? original.subscription_payment_method?.payment_method
          : '-';
      },
      show: true,
      width:200
    },

    {
      accessor: 'actions',
      Header: 'Actions',
      width: 200,
      Cell: ({ row: { original } }) => {
        console.log(original);
        return (
          <ActionButtons
            row={original}
            paymentMethod={
              original.subscription_payment_method?.payment_method
                ? original.subscription_payment_method.payment_method
                : 'ONLINE'
            }
          />
        );
      },
      show: true
    }
  ];
  const ActionButtons = ({ row, paymentMethod }) => {
    console.log('paymentMethod - ', paymentMethod);
    return (
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <IdentityInformationModal investorId={row.investor_id.investor_id} />
        <AccountInformationModal investorId={row.investor_id.investor_id} />
        {paymentMethod === 'OFFLINE' && onlyIssuer(role) && (
          <OfflineCashTransactionModal recordId={row.id} type="SUBSCRIPTION" />
        )}
        <Box sx={{ mt: 1 }}>
          <MailComponent email={row.investor_id.investor_email_id} subject="" body="" />
        </Box>
      </Box>
    );
  };

  return (
    <>
      <Typography
        variant="body2"
        color="initial"
        sx={{
          mt: 4,
          mb: 2,
          fontSize: '1.125rem',
          fontWeight: 'bold'
        }}
      >
        Subscriptions
      </Typography>

      <Card>
        <CardContent>
          <EnhancedTable
            tableTitle="Subscriptions"
            columns={headCells}
            refreshFunction={fetchWhiteListSubscriptions}
            data={whitelistedSubscriptions}
            skipPageReset={skipPageReset}
          />
        </CardContent>
      </Card>
    </>
  );
}
