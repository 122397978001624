export default function Input(theme) {
  return {
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontFamily: 'BeVietnam, Manrope',
          // color: '#0f0f36',
          color: '#000',
          lineHeight: '0.3rem',
          '[class*="dark-theme"] &': {
            // all labels inside any parent component with class dark-theme
            color: '#FFF',
            fontFamily: 'Manrope'
          }
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          fontWeight: 'normal',
          fontStretch: 'normal',
          fontStyle: 'normal',
          lineHeight: 1.5,
          letterSpacing: 'normal',
          textAlign: 'left',
          // color: '#8e8ea7',
          color: '#000',

          '&.Mui-error': {
            display: 'none'
          },
          '&..Mui-standard': {
            display: 'none'
          }
        }
      }
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: '#F4F4FA',
          color: '#000000',
          fontFamily: 'BeVietnamPro',

          '& .MuiOutlinedInput-notchedOutline': {
            border: '1px solid #AFAEC9'
          },

          '&.Mui-disabled': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.action.disabledBackground
            }
          },

          '&.Mui-error': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: '#ff5e5e'
            }
          },
          '&.Mui-focused': {
            '& .MuiOutlinedInput-notchedOutline': {
              // borderColor: '#0dd1ae',
              borderColor: 'FFF'
            }
          },

          '& input::placeholder': {
            fontFamily: 'BeVietnamPro, Manrope, sans-serif', // Add your desired font here
            color: '#b0b0b0' // Optional: Adjust placeholder text color
          },
          '[class*="dark-theme"] &': {
            // all labels inside any parent component with class dark-theme
            backgroundColor: '#1C19A7',
            color: '#98A6E6',
            border: '1px solid #2C2BA4',
            fontFamily: 'Manrope'
          }
        }
      }
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontFamily: 'BeVietnam, Manrope', // Apply font to the label of the checkbox
          color: '#000', // Optional: Set color for the checkbox label
          '[class*="dark-theme"] &': {
            color: '#FFF', // Change the color of the label in dark theme if needed
            fontFamily: 'Manrope'
          }
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontFamily: 'BeVietnam, Manrope', // Apply font to MenuItem
          color: '#000', // Optional: Set color for MenuItem text
          '[class*="dark-theme"] &': {
            color: '#FFF', // Adjust color in dark theme if needed
            fontFamily: 'Manrope'
          }
        }
      }
    }
  };
}
