import React, { useEffect, useState } from 'react';
import { Box, Grid, InputLabel, Typography, Button } from '@mui/material';
import Page from 'components/Page';
import SubscriptionHead from 'components/subscritiption/SubscriptionHead';
import EmptyDashboard from 'assets/images/emptyassets.png';
import { useLocation } from 'react-router';
import { useCoreTableState, useSubscriptionState, useWeb3, useHttpApi } from 'state';
import TokenSubCard from 'components/subscritiption/TokenSubCard';
import { useSnackbar } from 'notistack';
import { usePlaidLink } from 'react-plaid-link';
import EventBanner from './EventBanner';
import { API_URL } from 'config';

const DataNotAvailable = () => {
  return (
    <>
      <Box
        sx={{
          height: '60vh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <img src={EmptyDashboard} alt="Empty Dashboard" />
        <Typography variant="p">No Token available</Typography>
      </Box>
    </>
  );
};

const SubscriptionHome = () => {
  const { account } = useWeb3();
  const { tokens, fetchTokens } = useSubscriptionState();
  const { paymentMethod, fetchCountries } = useCoreTableState();
  const [filterTokens, setFilterTokens] = useState([]);
  const [sortissueType, setSortissueType] = useState('');
  const [sortbyCurrency, setSortbyCurrency] = useState('');
  const [sortbyIssuer, setSortbyIssuer] = useState('');
  const [isKycDone, setIsKycDone] = useState(false);
  const [isAuthDone, setIsAuthDone] = useState(false);
  const [isAstraAuthDone, setIsAstraAuthDone] = useState(false);
  const [astraAuthUrl, setAstraAuthUrl] = useState('');
  const [onlinePaymentEnabled, setOnlinePaymentEnabled] = useState(false);

  const [recentPerformance, setRecentPerformance] = useState('');
  const [upcomingEvent, setUpcomingEvent] = useState('');
  const {
    createLinkToken,
    getIDVList,
    exchangePublicToken,
    getProcessorToken,
    getAstraAccessToken,
    getBettingEvents
  } = useHttpApi();

  const [token, setToken] = useState(null);
  const [linkData, setLinkData] = useState(null);
  const location = useLocation();

  // This will check the kyc status and Auth Status and set its values.
  const [kycStatus, setKycStatus] = useState('Not Done');
  const [bankAccountVerificationStatus, setBankAccountVerificationStatus] = useState('Not Done');
  useEffect(() => {
    fetchTokens();
  }, []);
  const { open, ready, error, exit } = usePlaidLink({
    token,
    onSuccess: async (publicToken, metadata) => {
      setIsKycDone(true);
      setKycStatus('Done');
      if (publicToken) {
        const exchangeResponse = await exchangePublicToken(publicToken, metadata.account_id);
        setIsAuthDone(true);
        setBankAccountVerificationStatus('Done');
        await getProcessorToken();
      }
      setToken(null);
      window.location.reload();
    },
    onExit: async (err, metadata) => {
      console.log(
        `Exited early. Error: ${JSON.stringify(err)} Metadata: ${JSON.stringify(metadata)}`
      );
      setToken(null);
      window.location.reload();
    }
  });

  const getLinkToken = async (product) => {
    const response = await createLinkToken(product);
    console.log('response from create token - - - -', response.link_token);
    setToken(response.link_token);
  };

  const startAstraVerification = () => {
    // Do ASTRA Verification.
    window.location.href = astraAuthUrl;
    // console.log('astra auth url - - - -', astraAuthUrl);
  };

  useEffect(() => {
    if (token) {
      // this token is the plaid link token
      if (ready) {
        open(); // Open the Plaid Link flow when token is set and link is ready
      }
    }
  }, [token, ready]); // Runs when `token` or `ready` changes

  const { enqueueSnackbar } = useSnackbar();

  // This useEffect will get all the PLAID related data on page load.
  useEffect(async () => {
    const idvLists = await getIDVList();
    if (idvLists.astra_status === true) {
      setIsAstraAuthDone(true);
    }
    setAstraAuthUrl(idvLists.astra_auth_url);

    if (idvLists.auth_status === true) {
      setIsAuthDone(true);
      setBankAccountVerificationStatus('Done');
    }
    if (idvLists?.idv_response?.identity_verifications?.[0]?.status === 'success') {
      setIsKycDone(true);
      setKycStatus('Done');
    } else if (idvLists?.idv_response?.identity_verifications?.[0]?.status) {
      setKycStatus(idvLists?.idv_response?.identity_verifications?.[0]?.status);
    }
    // checking if online payment is enabled
    if (idvLists.payment_mode === true) {
      setOnlinePaymentEnabled(true);
    }

    const queryParams = new URLSearchParams(location.search);

    const authCode = queryParams.get('authorization_code');

    // Use authCode as needed
    if (authCode) {
      // get the access code from the Auth Code.
      enqueueSnackbar('Astra Authorization completed. Click Invest again to buy tokens.', {
        variant: 'success'
      });
      await getAstraAccessToken(authCode);
      setIsAstraAuthDone(true);
    }
  }, []);

  useEffect(() => {
    const _tokens = tokens.filter(
      (item) =>
        item.issue_type_id.issue_type_name === sortissueType ||
        item.token_currency.currency_code === sortbyCurrency ||
        item.issuer_id.issuer_name === sortbyIssuer
    );
    setFilterTokens(_tokens);
  }, [sortbyCurrency, sortissueType, sortbyIssuer, tokens]);
  const Data = () => {
    return (
      <>
        <Grid container spacing={3}>
          {console.log('filter tokens from investor- - ', filterTokens)}
          {filterTokens.map((token, index) => (
            <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
              <TokenSubCard
                {...token}
                account={account}
                isKycDone={isKycDone}
                isAuthDone={isAuthDone}
              />
            </Grid>
          ))}
        </Grid>
        {filterTokens.length === 0 && <DataNotAvailable />}
      </>
    );
  };

  const fetchBettingEvents = async () => {
    try {
      const events = await getBettingEvents();
      console.log('🚀 ~ fetchBettingEvents ~ events:', events);
      setRecentPerformance(events.recent_performance);
      setUpcomingEvent(events.upcoming_event);
    } catch (error) {
      console.log('Error in fetching betting events', error);
    }
  };

  useEffect(() => {
    fetchBettingEvents();
  }, []);
  return (
    <Page title="Token Dashboard">
      <SubscriptionHead title="Token Dashboard" />
      <Box sx={{ mx: 10, mt: 3 }}>
        {/* Adding the buttons and label */}
        <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center">
          {/* First row in the first column */}

          <Grid item>
            <InputLabel>
              <Typography sx={{ fontFamily: 'BeVietnamPro' }}>KYC -</Typography>
            </InputLabel>
          </Grid>
          <Grid item mr={3}>
            <Button
              variant="contained"
              size="small"
              onClick={() => getLinkToken('KYC')}
              disabled={isKycDone}
            >
              {isKycDone ? 'Done' : 'Complete KYC'}
            </Button>
          </Grid>
          <Grid item>
            <InputLabel>
              <Typography sx={{ fontFamily: 'BeVietnamPro' }}>
                Bank Account Verification -
              </Typography>
            </InputLabel>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              size="small"
              onClick={() => getLinkToken('AUTH')}
              disabled={(!isKycDone && !isAuthDone) || isAuthDone}
            >
              {isAuthDone ? 'Done' : 'Complete Auth'}
            </Button>
          </Grid>

          {onlinePaymentEnabled && (
            <>
              <Grid item ml={3}>
                <InputLabel style={{ color: isAstraAuthDone === true ? 'green' : 'inherit' }}>
                  ASTRA Verification - {isAstraAuthDone === true ? 'Done' : 'Not Done'}
                </InputLabel>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => startAstraVerification()}
                  disabled={!isAuthDone || isAstraAuthDone}
                >
                  Complete ASTRA Verification
                </Button>
              </Grid>
            </>
          )}
          {/* Second row in the first column */}
        </Grid>

        {/* TOKEN DISPLAY */}
        {tokens.length !== 0 ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              width: '100%'
              // position: 'relative'
            }}
          >
            {/* Event Banner: Past/Ongoing Event */}
            <EventBanner
              bannerImg={
                recentPerformance &&
                (!recentPerformance?.startsWith('http:')
                  ? `${API_URL}${recentPerformance.substring(1)}`
                  : `${recentPerformance}`)
              }
            />

            {!sortissueType && !sortbyCurrency && !sortbyIssuer ? (
              <Box
                sx={{
                  mx: 5,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start'
                }}
              >
                {tokens.map((token, index) => (
                  <Box key={index}>
                    <TokenSubCard
                      {...token}
                      account={account}
                      isKycDone={isKycDone}
                      isAuthDone={isAuthDone}
                      isAstraAuthDone={isAstraAuthDone}
                    />
                  </Box>
                ))}
              </Box>
            ) : (
              <Data />
            )}

            {/* Event Banner: Upcoming Banner */}
            <EventBanner
              bannerImg={
                upcomingEvent &&
                (!upcomingEvent?.startsWith('http:')
                  ? `${API_URL}${upcomingEvent.substring(1)}`
                  : `${upcomingEvent}`)
              }
            />
          </Box>
        ) : (
          <DataNotAvailable />
        )}
      </Box>
    </Page>
  );
};

export default SubscriptionHome;
