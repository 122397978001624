import React, { useEffect, useState } from 'react';
import EnhancedTable from 'components/tables/EnhancedTable';
import { useAuthStateShared, useSubscriptionState, useWeb3 } from 'state';
import AddressFieldTools from 'components/AddressFieldTools';
import { Typography } from '@mui/material';
import InvestorTableSummary from './InvestorTableSummary';

export default function IssuerViewRedemptions({ poolAddr }) {
  const { redeemedAllotments, fetchRedeemedAllotments } = useSubscriptionState();
  const [data, setData] = useState(redeemedAllotments);
  const { account } = useWeb3();
  const [skipPageReset, setSkipPageReset] = React.useState(false);

  const fetchAccount = poolAddr || account;

  useEffect(() => {
    fetchRedeemedAllotments(fetchAccount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [poolAddr, account]);

  useEffect(() => {
    setData(redeemedAllotments);
  }, [redeemedAllotments]);

  const refreshAllotments = () => {
    fetchRedeemedAllotments(fetchAccount);
  };

  const headCells = [
    // {
    //   accessor: 'token.token_symbol',
    //   Header: 'Token Symbol',
    //   width: 80,
    //   show: true
    // },
    // {
    //   accessor: '',
    //   Header: 'Token Address',
    //   show: true,
    //   width: 200,
    //   Cell: ({ row: { original } }) => {
    //     return (
    //       <AddressFieldTools
    //         address={original.token.deployment_address}
    //         decimals={0}
    //         showCopyButton
    //         showInBlockExplorer
    //       />
    //     );
    //   }
    // },
    {
      accessor: 'token.token_name',
      Header: 'Token Name',
      width: 90,
      show: true
    },
    {
      accessor: 'redeemed_qty',
      Header: 'Quantity',
      width: 70,
      show: true
    },
    {
      accessor: 'redemption_date',
      Header: 'Redemption Date',
      width: 110,
      show: true,
      Cell: ({ row: { original } }) => {
        return <>{original.redemption_date ?? '-'}</>;
      }
    },
    {
      accessor: 'buy_price',
      Header: 'Buy Price',
      width: 80,
      show: true
    },
    {
      accessor: 'token_price',
      Header: 'Redemption Price',
      width: 110,
      show: true
    },
    {
      accessor: 'redemption_status_title',
      Header: 'Redemption Status',
      width: 110,
      show: true,
      Cell: ({ row: { original } }) => {
        return <>{original.redemption_status_title.replace('_', ' ')}</>;
      }
    }
  ];

  const updateMyData = (rowIndex, columnId, value) => {
    setSkipPageReset(true);
    setData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value
          };
        }
        return row;
      })
    );
  };

  return (
    <>
    {data?.length > 0  && <InvestorTableSummary data={data[0]} />}
      <EnhancedTable
        tableTitle="InvestorViewRedemption"
        columns={headCells}
        data={data}
        setData={setData}
        updateMyData={updateMyData}
        skipPageReset={skipPageReset}
        refreshFunction={refreshAllotments}
        setMinWidth
      />
    </>
  );
}
