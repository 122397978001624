// ----------------------------------------------------------------------

export default function Button() {
  return {
    MuiButton: {
      variants: [
        {
          props: { variant: 'gradient' },
          style: {
            background: 'linear-gradient(90deg, #007BFF 0%, #10C6FF 100%)',
            color: '#fff',

            '&:hover': {
              animation: 'rotateGradient 500ms forwards'
            },
            animation: 'rotateGradientReverse 500ms forwards'
          }
        },
        {
          props: { variant: 'contained-gradient' },
          style: {
            color: '#fff',
            transition: `background 300ms ease-out`,
            '&:hover': {
              background: 'linear-gradient(90deg,  #10C6FF  0%,#007BFF 100%)'
            }
          }
        }
      ],
      styleOverrides: {
        root: {
          // borderRadius: '6px',
          textTransform: 'none'
        },
        gradient: {
          // background: `linear-gradient(to right, #25aae1 7%, #18bfc5 57%, #0dd1ae 97%)`,
          background: `linear-gradient(to right, #007BFF 10%, #10C6FF 100%)`,
          color: '#fff'
        },
        text: {
          color: '#fff',
          fontFamily: 'Hind'
        },
        containedSecondary: {
          backgroundColor: '#112b3d'
        },
        containedError: {
          backgroundColor: '#ff5e5e'
        },
        outlined: {
          border: '1px solid #36405d',
          color: '#36405d'
        },
        contained: {
          backgroundColor: '#5178f9'
          // '&:hover': {
          //   background: `linear-gradient(to right, #007BFF 10%, #10C6FF 100%)`,
          //   color: '#fff' // Adjust text color on hover
          // }
        },
        sizeLarge: {
          height: 48
        }
      }
    }
  };
}
