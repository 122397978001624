import {
  Typography,
  IconButton,
  Box,
  TextField,
  Button,
  FormLabel,
  Checkbox,
  FormGroup,
  FormControlLabel
} from '@mui/material';
import Modal from '@mui/material/Modal';
import { useState, useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useWeb3State } from 'state/useWeb3State';
import { useAppState, useBusinessEntityState, useHttpApi } from 'state';
import { useSnackbar } from 'notistack';
import LoadingActionButton from 'helpers/LoadingActionButton';
import { toChecksumAddress } from 'helpers/web3';
import { xdcRegex } from 'helpers/regex';
import { CheckBox } from '@mui/icons-material';

const BTLTransferModal = ({ open, setOpenModal, tokenBalance }) => {
  const [transferQuantity, setTransferQuantity] = useState('');
  const [processTransfer, setProcessTransfer] = useState(false);
  const { account, transfer } = useWeb3State();
  const { currentToken } = useBusinessEntityState();
  const { throwErrorMessage } = useAppState();
  const { enqueueSnackbar } = useSnackbar();
  const [transferToAddress, setTransferToAddress] = useState('');
  const { getReservedTokenCount } = useHttpApi();

  const regex = new RegExp(xdcRegex);
  const handleClose = () => {
    setTransferQuantity('');
    setTransferToAddress('');
    setOpenModal(false);
    setProcessTransfer(false);
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableEscapeKeyDown
      disableEnforceFocus
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          width: 'auto',
          p: 4,
          boxShadow: 24,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          borderRadius: '10px'
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'flex-start', mt: 2 }}>
          <Button sx={{ width: '15rem', mx: 2 }} variant="contained">
            BTL Balance
          </Button>
          <TextField
            fullWidth
            size="small"
            autoComplete="off"
            type="text"
            placeholder="Enter quantity to mint"
            id="mintQuantity"
            inputProps={{ maxLength: 50, readOnly: true, width: 'auto' }}
            value={tokenBalance}
          />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-start', mt: 2 }}>
          <Button sx={{ width: '15rem', mx: 2 }} variant="contained">
            Transfer From
          </Button>
          <TextField
            fullWidth
            size="small"
            autoComplete="off"
            type="text"
            placeholder="Enter quantity to mint"
            id="mintQuantity"
            inputProps={{ maxLength: 50, readOnly: true, width: 'auto' }}
            value={account}
          />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-start', mt: 2 }}>
          <Button sx={{ width: '15rem', mx: 2 }} variant="contained">
            Tokens
          </Button>
          <TextField
            fullWidth
            size="small"
            autoComplete="off"
            type="number"
            placeholder="Enter Token Quantity"
            id="tokenQuantity"
            inputProps={{ maxLength: 50, width: 'auto' }}
            onChange={(e) => {
              setTransferQuantity(e.target.value);
              console.log('count,', transferQuantity, transferToAddress);
            }}
          />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-start', mt: 2 }}>
          <Button sx={{ width: '15rem', mx: 2 }} variant="contained">
            Transfer To
          </Button>
          <TextField
            fullWidth
            size="small"
            autoComplete="off"
            type="text"
            placeholder="Enter Recipient's Address "
            id="transferToAddress"
            inputProps={{ maxLength: 50, width: 'auto' }}
            onChange={(e) => {
              setTransferToAddress(e.target.value);
              console.log('count,', transferQuantity, transferToAddress);
            }}

            // value={transferToAddress}
          />
        </Box>
        {transferQuantity && transferToAddress && (
          <Box sx={{ display: 'flex', justifyContent: 'flex-start', mt: 2, mx: 2 }}>
            <FormGroup>
              <FormControlLabel
                required
                control={<Checkbox />}
                onChange={(e) => {
                  setProcessTransfer(e.target.checked);
                }}
                label={`Are you sure you want to transfer ${transferQuantity} tokens from your account to the recipient's account?`}
              />
            </FormGroup>
          </Box>
        )}
        <Box sx={{ display: 'flex', justifyContent: 'flex-start', mt: 2 }}>
          <LoadingActionButton
            loadingPosition="start"
            sx={{
              width: '7.5rem',
              height: '2.5rem',
              mt: 3,
              mx: 1
            }}
            variant="contained"
            disabled={!processTransfer}
            onClick={async () => {
              try {
                if (transferQuantity > 0 && transferToAddress) {
                  let transferToAddressChecksum;
                  try {
                    transferToAddressChecksum = toChecksumAddress(transferToAddress);
                    if (transferToAddressChecksum === account) {
                      enqueueSnackbar(
                        `Self Transfer of tokens is not allowed, please change the recipient's address`,
                        {
                          variant: 'error'
                        }
                      );
                      return;
                    }
                  } catch (e) {
                    throwErrorMessage(e);
                    return;
                  }
                  const reservedTokenCount = await getReservedTokenCount(currentToken?.token_id);

                  if (tokenBalance < Number(reservedTokenCount.data) + Number(transferQuantity)) {
                    enqueueSnackbar(
                      'You do not have sufficient tokens for transfer due to other open transactions in progress',
                      { variant: 'error' }
                    );
                    return;
                  } else {
                    await transfer(
                      currentToken?.deployment_address,
                      transferToAddressChecksum,
                      transferQuantity
                    );
                    enqueueSnackbar('Token Transfer in progress', {
                      variant: 'success'
                    });
                    handleClose();
                  }
                } else if (!transferQuantity || transferQuantity <= 0) {
                  enqueueSnackbar('Enter a valid token quantity', { variant: 'error' });
                } else {
                  enqueueSnackbar('Enter valid data', { variant: 'error' });
                }
                setProcessTransfer(false);
              } catch (e) {
                throwErrorMessage(e);
              }
            }}
          >
            Transfer
          </LoadingActionButton>
          <Button
            sx={{ width: '7.5rem', height: '2.5rem', mt: 3, mx: 1, backgroundColor: 'black' }}
            variant="contained"
            onClick={handleClose}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default BTLTransferModal;
