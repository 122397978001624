import { Typography, Divider, Box, Button } from '@mui/material';
import React from 'react';
import YPLogo from '../../assets/images/yplogo.png';
import XinFinLogo from '../../assets/images/xinfinnetworklogo.png';
import { TNC_URL, PRIVARY_POLICY_URL } from '../../config';

const Footer = () => {
  return (
    <Box>
      <Divider
        sx={{
          mt: 5
        }}
      />
      <Box
        sx={{
          backgroundColor: '#000000',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          px: 5
        }}
      >
        <Box sx={{ flex: 1, textAlign: 'left' }}>
          <Typography variant="body1" sx={{ py: 2, color: '#fff' }}>
            &copy; {new Date().getFullYear()} BettorToken. All rights reserved.
          </Typography>
        </Box>
        <Box sx={{ flex: 1, textAlign: 'center' }}>
          <Typography variant="body1" sx={{py: 2, color: '#fff' }} on>
            <Button onClick={() => {
                            window.open(PRIVARY_POLICY_URL, '_blank');
              }}><Typography>Privacy Policy</Typography></Button>
                           | 
            <Button  onClick={() => {
                  window.open(TNC_URL, '_blank');
              }}><Typography>Terms of Use</Typography></Button>
          </Typography>
        </Box>
        <Box sx={{ flex: 1, textAlign: 'right', display:'flex', direction:'row', alignItems:'center', justifyContent:'flex-end' }}>
          <Typography variant="body1" sx={{ py: 2, color: '#fff', mr:2 }}>
            Powered by -
            </Typography>
            <Box
              component="img"
              src={YPLogo}
              alt="YodaPlus"
              sx={{
                // position: 'absolute',
                // top: '20px',
                // left: '20px',
                width: '100px',
                height:'25px',
                zIndex: 1,
                mr:'6px'
              }}
            />
            <Box
              component="img"
              src={XinFinLogo}
              alt="XinFin"
              sx={{
                // position: 'absolute',
                // top: '20px',
                // left: '20px',
                width: '100px',
                height:'50px',
                zIndex: 1,
              }}
            />
          
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
